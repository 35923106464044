import React, { useEffect } from 'react'
import SelectedProduct from './AddedProduct'
import Bar from './Bar'

function Demand() {
    

  return (
    // <div>
      <Bar/>
    
    // </div>
  )
}

export default Demand
