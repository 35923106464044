import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import ReceiveModal from './ReceiveModal';
import Loader from '../../Loader/Loader';
// import './ViewRequestModal.css';
// import '../Modal.css';
import TableComponentId from '../../Table Component/tableComponent';

const ApproveModal = ({ isApproveModalOpen, setIsApproveModalOpen, request_id }) => {
    const dispatch = useDispatch();
    const [isReceiveModalOpen, setIsReceiveModalOpen] = useState(false);
    const [product, setProduct] = useState([]);
    const { loading, error, currentDemand } = useSelector((state) => state.allDemand);
    const isAllProductReceived = currentDemand?.products?.every(product => product.received_quantity !== null);
    const handleRecieve = "handleReceive"
    const handleReject = (id) => {
        console.log(id);
    };

    const handleReceive = (product) => {
        console.log(product)
        setProduct(product);
        setIsReceiveModalOpen(!isReceiveModalOpen);
    };

    const clickCloseIcon = () => {
        setIsApproveModalOpen(!isApproveModalOpen);
    };


    const columns = [
        { field: "_id.name", label: "Product Name" },
        { field: "quantity", label: "Requested Quantity" },
        { field:  "received_quantity", label: "Received Quantity" },
      ];

      const actions = [
        {
          label: "handleReceive",
          color: "yellow",
          handler: (itemId) => handleReceive(itemId),
        },
   
      ];

    return (
        <Fragment>
            {loading ? (
                <div className="modal">
                <div className="modal-content">
                    <Loader />
                    </div></div>
            ) : (<>
                            <div className="modal">
                            <div className="modal-content">
                                <div className='buttonDiv'>
                                    <button onClick={clickCloseIcon}><CloseIcon /></button>
                                </div>
                                <div className="headerHeading">
                                    <h2>Pir Mehr Ali Shah Arid Agriculture University Rawalpindi</h2>
                                    <p>University institute of information Technology </p>
                                </div>
                               
                                <div className="model-header-container">
                                    <div className='header-Row'>
                                        <h5>Application ID:&nbsp;</h5>
                                        <h6>{currentDemand?.applicationId}</h6>
                                    </div>
                                    <div className='header-Row'>
                                        <h5>Date:&nbsp;</h5>
                                        <h6>{currentDemand?.date}</h6>
                                    </div>
                                    <div className='header-Row'>
                                        <h5>Status:&nbsp;</h5>
                                        <h6>{currentDemand?.status}</h6>
                                    </div>
                                </div>
                            <div className="View_Modal_Table_div">
                                <div className="View_Modal_Table_wrapper">
                                    <></>
                                    <TableComponentId
                                        data={currentDemand.products}
                                        columns={columns}
                                        actions={actions}
                                        handleReceive={handleRecieve}
                                    />
                                 
                                </div>
                            </div>
                            </div>
                            </div>
            </>)}
            {isReceiveModalOpen && (
                <ReceiveModal
                    isReceiveModalOpen={isReceiveModalOpen}
                    setIsReceiveModalOpen={setIsReceiveModalOpen}
                    isApproveModalOpen={isApproveModalOpen}
                    setIsApproveModalOpen={setIsApproveModalOpen}
                    product={product}
                    request_id={request_id}
                    locationId={currentDemand.locationId}
                />
            )}
            {/* {loading ? (
                <Loader />
            ) : (
                <Modal className="Modal" size="lg" isOpen={isApproveModalOpen} toggle={clickCloseIcon}>
                    <ModalHeader>
                        <FontAwesomeIcon className="svg-icon" icon={faTimes} style={{ float: 'right', cursor: 'pointer' }} onClick={clickCloseIcon} />
                    </ModalHeader>
                    <ModalBody>
                        <div className="Request_Details_Modal_tables">
                            <table className="Request_Details_Modal_table">
                                <tbody>
                                    <tr>
                                        <th>Application ID</th>
                                        <td>{currentDemand?.applicationId}</td>
                                    </tr>
                                    <tr>
                                        <th>Date</th>
                                        <td>{currentDemand?.date}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{currentDemand?.status}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="View_Modal_Table_div">
                            <div className="View_Modal_Table_wrapper">
                                <table className="View_Modal_Table">
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Requested Quantity</th>
                                            {currentDemand.status !== 'Pending' && <th>Received Quantity</th>}
                                            {!isAllProductReceived && <th>Action</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentDemand.products?.map(product => (
                                            <tr key={product._id}>
                                                <td>{product._id.name}</td>
                                                <td>{product.quantity}</td>
                                                {currentDemand.status !== 'Pending' && <td>{product.received_quantity}</td>}
                                                {!product.received_quantity && (
                                                    <td><button className="button" onClick={() => handleReceive(product)}>Receive</button></td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            )}
            {isReceiveModalOpen && (
                <ReceiveModal
                    isReceiveModalOpen={isReceiveModalOpen}
                    setIsReceiveModalOpen={setIsReceiveModalOpen}
                    isApproveModalOpen={isApproveModalOpen}
                    setIsApproveModalOpen={setIsApproveModalOpen}
                    product={product}
                    request_id={request_id}
                    locationId={currentDemand.locationId}
                />
            )} */}
        </Fragment>
    );
};

export default ApproveModal;
