import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import "./Bar.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADD_DEMAND_DATA, DEMAND_DETAIL } from "../../../Redux/constants/demandConstants";
import { getAllLocation, clearError } from "../../../actions/demandAction";
import {State} from "../DemandContext/context";
import { useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { useAlert } from "react-alert";
import SelectedProduct from './AddedProduct'
import { MdAdfScanner } from "react-icons/md";
function Bar({ print }) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const addedProductRef = useRef(); 
  const locationRecord = useLocation()
  const { detail } = useSelector((state) => state.demandReducer);
  const [formattedDate, setFormattedDate] = useState()
  const { loading, allLocation, error } = useSelector(
    (state) => state.allLocation
  );
  const { roleTask } = useSelector((state) => state.userData);

  const navigate = useNavigate();
  const {    
      applicationId, 
      setApplicationId,
      date, 
      setDate,
      subject, 
      setSubject,
      description, 
      setDescription,
      locationId, 
      setLocationId, demandList, setDemandList} = useContext(State)


  useEffect(() => {
    const previousPath = locationRecord.state ? locationRecord.state : null;
    const isPreviousPathMatching = previousPath === '/demandproduct' ;
    console.log('Is previous path matching:', isPreviousPathMatching);
    if(!isPreviousPathMatching)
    {
      setApplicationId('')
      setDate('')
      setFormattedDate('')
      setLocationId('')
      setSubject('')
      setDescription('')
      setDemandList([])
      dispatch({ type: ADD_DEMAND_DATA, payload: [] })
    }
  }, [locationRecord.state]);
  useEffect(() => {
    if (error) {
      alert.error(error);
      return () => dispatch(clearError());
    } else {
      dispatch(getAllLocation());
    }
  }, [error]);

  // const handlePrint = () => {
  //     print();
  // };

  const handleApplicationIdChange = (e) => {
    setApplicationId(e.target.value);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setFormattedDate(e.target.value)
    // setDate(selectedDate);

    if (selectedDate) {
      const [year, month, day] = selectedDate.split('-');
      const newFormattedDate = `${day}-${month}-${year}`;
      console.log(newFormattedDate)
      setDate(newFormattedDate);
    } else {
      setDate('');
    }
    // setDate(e.target.value);
  };

  useEffect(()=>{
    if(allLocation?.length > 0)
    {
      allLocation?.map((location)=>{
        if(location?.name === "UIIT Store")
        {
          setLocationId(location?._id)
        }
      })
    }
  }, [allLocation])

  const handleLocationChange = (e) => {
    setLocationId(e.target.value);
    console.log(e.target.value)
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleViewDemandClick = () => {
    navigate("/viewdemand");
  };

  const handleAddItem = () => {
    dispatch({
      type: DEMAND_DETAIL,
      payload: {
        Subject: subject,
        ApplicationID: applicationId,
        Date: date,
        locationId: locationId,
      },
    });
    navigate("/demandproduct", {state: "/demand"});
  };
  const allFieldsFilled =
    applicationId.trim() !== "" &&
    date.trim() !== "" &&
    subject.trim() !== "" &&
    locationId.trim() !== "";

  var task = false;
  task = roleTask.find((e) => e?.task_id?.name === "View Product Demand" && e.status === true);
 
  const callHandleDemandSave = () => {
    if (addedProductRef.current) {
      addedProductRef.current.handleDemandSave();
    }
  };
  return (
    <>
        <div className={`Demand`}>
          <div className="secondContainer">
            <div className="contentt-box">
              <div className="heading-container">
                <h3>{"Demand"}</h3>
              </div>
            </div>
          <div className="Demand-Input-Section">
            <div className="formApp">
              <div className="formRow">
                <div className="inputSection">
                  <label className="required">Application id: </label>
                  <input
                    type="text"
                    required
                    className="yellow_border"
                    value={applicationId}
                    onChange={handleApplicationIdChange}
                    disabled={demandList?.length > 0}
                    placeholder="Enter application id" />
                </div>
                <div className="inputSection">
                  <label className="required">Application date:</label>
                  <input
                    type="date"
                    required
                    className="yellow_border"
                    value={formattedDate}
                    onChange={handleDateChange} 
                    disabled={demandList?.length > 0}/>
                </div>
                <div className="inputSection">
                  <label className="required">Location :</label>
                  <select
                    className="dropdownselect"
                    required
                    value={locationId}
                    onChange={handleLocationChange}>
                    disabled={demandList?.length > 0}
                    <option value="" disabled>
                      Select location
                    </option>
                    {allLocation?.map((location) => (
                      <option key={location._id} value={location._id}>
                        { location.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="formRow">
              <div className="inputSection">
                  <label className="required">Subject: </label>
                  <textarea
                    type="text"
                    required
                    className="yellow_border"
                    value={subject}
                    onChange={handleSubjectChange}
                    placeholder="Enter subject" 
                    disabled={demandList?.length > 0}/>
                </div>
                <div className="inputSection">
                  <label className="required">Description: </label>
                  <textarea
                    type="text"
                    required
                    className="yellow_border"
                    value={description}
                    onChange={handleDescription}
                    disabled={demandList?.length > 0}
                    placeholder="Enter subject" />
                </div>
            
              </div>
              {/* <div className="buttonRow">
                <button
                  className={`button button-add-product ${
                  allFieldsFilled ? "clicked" : "Notclicked"
                  }`}
                  disabled={!allFieldsFilled}
                  onClick={handleAddItem}>
                  Add Item
                </button>
                <button
                  className={`button button-add-product ${
                  allFieldsFilled ? "clicked" : "Notclicked"
                  }`}
                  // disabled={!allFieldsFilled}
                  onClick={callHandleDemandSave()}>
                  Add Item
                </button>
              </div> */}
            </div>  
          </div>
          {/* {
            (applicationIdInput, dateInput, locationInput, subjectInput, description) && (<> <SelectedProduct allFieldsFilled={allFieldsFilled} onCallFunction={handleAddItem} /></>)
          } */}
           <SelectedProduct allFieldsFilled={allFieldsFilled} onCallFunction={handleAddItem}/>
          </div>
        </div>
    </>
    // <div>
    //   {loading ? (
    //     <div style={{ width: "100%", height: "100vh" }}>
    //       <Loader />
    //     </div>
    //   ) : error ? (
    //     <div>error</div>
    //   ) : (
    //     <div className="main-page-container" style={{ paddingTop: "60px" }}>
    //       <div className="pageName_And_Button">
    //         <h2 className="add-visa-type-title">Add Demand</h2>

    //         {task ? (
    //           <button className="button-yellow" onClick={handleViewDemandClick}>
    //             View Demand
    //           </button>
    //         ) : null}
    //       </div>
    //       <div className="input-bar" style={{ paddingTop: "20px" }}>
    //         <div className="input-container">
    //           <div className="input-with-label">
    //             <label className="required">Subject: </label>
    //             <textarea
    //               type="text"
    //               required
    //               className="yellow_border"
    //               value={subjectInput}
    //               onChange={handleSubjectChange}
    //               placeholder="Enter subject"></textarea>
    //           </div>
    //           <div className="input-with-label">
    //             <label className="required">Application id: </label>
    //             <input
    //               type="text"
    //               required
    //               className="yellow_border"
    //               value={applicationIdInput}
    //               onChange={handleApplicationIdChange}
    //               placeholder="Enter application id"></input>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="input-bar" style={{ paddingTop: "20px" }}>
    //         <div className="input-container">
    //           <div className="input-with-label">
    //             <label className="required">Application date:</label>
    //             <input
    //               type="date"
    //               required
    //               className="yellow_border"
    //               value={dateInput}
    //               onChange={handleDateChange}></input>
    //           </div>
    //           <div className="input-with-label">
    //             <label className="required">Location :</label>
    //             <select
    //               className="yellow_border"
    //               required
    //               value={locationInput}
    //               onChange={handleLocationChange}>
    //               <option value="" disabled>
    //                 Select location
    //               </option>
    //               {allLocation?.map((location) => (
    //                 <option key={location._id} value={location._id}>
    //                   {location.name}
    //                 </option>
    //               ))}
    //             </select>
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         className="clear-and-Add-button-container"
    //         style={{ marginTop: "10px" }}>
    //         {
    //           <button
    //             className="clear-And-Add-Record-button"
    //             disabled={!allFieldsFilled}
    //             onClick={handleAddItem}>
    //             Add Item
    //           </button>
    //         }
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
}

export default Bar;
