import React from 'react'
import AllProduct from './AllProduct'
function AddDemandProduct() {
  return (<>
  <AllProduct/>
  </>
  )
}

export default AddDemandProduct
