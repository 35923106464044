// src/AddVisaType.js
import React, { useEffect, useState } from 'react';
import { addProductType, clearError, clearMessage } from '../../../actions/productAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader/Loader'; // Assuming you have a Loader component or a library
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import swal from "sweetalert2";
import TypeRolesModal from './typeRolesModal';
let typeAssigned= []
const AddProductType = () => {
    const navigate=useNavigate()
    const alert=useAlert()
    const dispatch = useDispatch();
    const { loading, message, error } = useSelector((state) => state.product);
    const [productType, setProductType] = useState()
    const [skipButton, setSkipButton] = useState(false)
    const [typeDescription, setTypeDescription] = useState()
    const [openRoleModal, setOpenRoleModal] = useState(false)
    const [assignToRole, setAssignToRole] = useState([])
    const [formData, setFormData] = useState({
        productType: '',
        typeDescription: '',
    });

    const handleTypeChange = (e) => {
        setProductType(e.target.value)
    };

    const handleClear = () => {
        setProductType('')
        setTypeDescription('')
        setAssignToRole([])
    };
    const handleViewTypeClick =()=>{
        navigate('/producttype')
    }

    const handleSubmit = async (e) => {
        setOpenRoleModal(true)
        // e.preventDefault();
        // try {
        //    await dispatch(addProductType(formData));
        //    handleClear();
        //    navigate('/producttype')
        // } catch (error) {
        //     console.error('Error adding record:', error);
        // }
    };

    const handleSubmitRecord = async (buttonClick) => {

        // console.log()
        // console.log(skipButton)
        if(assignToRole < 1 && buttonClick === 'submit')
        {
            console.log("bhoe")
            swal.fire({
                icon: "error",
                title: "Please select roles to assign category",
                showCancelButton: false,
                confirmButtonText: "ok",
                customClass: {
                  popup: "custom-swal-popup", // This is the custom class you're adding
                },
              })
        }else if(buttonClick === 'Skip') 
            {
                console.log("helloae")
                swal.fire({
                    icon: "warning",
                    title: "Are you sur you want to add category without selecting roles",
                    showCancelButton: true,
                    cancelButtonText: "No",
                    confirmButtonText: "Yes",
                    customClass: {
                        popup: 'popup-desing',
                        title: 'swal2-title-custom',
                        content: 'swal2-content-custom'
                    }
                })
            .then(async (result) => {
                if (result.value) {
                    setOpenRoleModal(true)
        // e.preventDefault();
                    try {
                        console.log("true")
                        console.log(assignToRole)
                        typeAssigned=[]
                        setSkipButton(true)
                        setAssignToRole([])
                        await dispatch(addProductType(productType, typeDescription, typeAssigned));
                        handleClear();
                        navigate('/producttype')
                    } catch (error) {
                    console.error('Error adding record:', error);
                    }
        }
   
    })
}   else if(assignToRole.length > 0){
    console.log(assignToRole, 'afejo;af')
    typeAssigned=[]
    await dispatch(addProductType(productType, typeDescription, assignToRole));
    handleClear();
    navigate('/producttype')
}
    };
    useEffect(() => {
        if (message) {
            alert.success(message);
            setAssignToRole([])
            setProductType('')
            setTypeDescription('')
            dispatch(clearMessage());
        }
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }
    }, [message, error, dispatch]);


    const { roleTask } = useSelector(
        (state) => state.userData
      );
      var task = false;
      task = roleTask.find((e) => e?.task_id?.name === "View Product Type" && e.status === true);
      

    return (
        <>
        <div className={`Product`}>
            <div className="secondContainer">
                <div className="contentt-box">
                    <div className="heading-container">
                        <h3>{"Add Category"}</h3>
                    </div>
                </div>
                <div className="form">
                    <div className="formRow">
                        <div className="inputSection">
                            <label className='required'>Category</label>
                            <input
                                type="text"
                                name="productType"
                                placeholder="Enter Category"
                                value={productType}
                                onChange={handleTypeChange}
                                className="add-visa-type-input yellow_border"
                                required
                                disabled={loading}
                            />
                        </div>
                        <div className="inputSection">
                            <label className='required'>Description</label>
                            <input
                                type="text"
                                name="typeDescription"
                                placeholder="Enter Type Description"
                                value={typeDescription}
                                onChange={(e)=> setTypeDescription(e.target.value)}
                                className="add-visa-type-input yellow_border"
                                required
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <div className="buttonRow">
                        <button type="submit" className="button button-back" onClick={handleViewTypeClick}>Back</button>
                        <button type="submit" className={`button button-add-product`} onClick={handleSubmit}>Add Category</button>
                    </div>
                </div>
            </div>
            {
                openRoleModal && <TypeRolesModal 
                typeAssigned={typeAssigned}
                openRoleModal={openRoleModal} 
                setOpenRoleModal={setOpenRoleModal} 
                setAssignToRole={setAssignToRole}
                skipButton={skipButton}
                setSkipButton={setSkipButton}
                assignToRole={assignToRole}
                handleSubmitRecord = {handleSubmitRecord}/>
               
            }
        </div>
    </>
        // <div className="main-page-container">
        //     <div className='pageName_And_Button'>
        //         <h2 className="add-visa-type-title">Add Product Type</h2>
        //         {task ? <button className="button-yellow" onClick={handleViewTypeClick} disabled={loading}>View Types</button>: null}
                
        //     </div>
        //     {loading ? (
        //         <div className="loader-container">
        //             <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
        //         </div>
        //     ) : (
        //         <form className="input-bar" onSubmit={handleSubmit}>
        //             <div className='input-container'>
        //                 <div className='input-with-label'>
        //                     <label className='required'>Type</label>
        //                     <input
        //                         type="text"
        //                         name="productType"
        //                         placeholder="Enter product type"
        //                         value={formData.productType}
        //                         onChange={handleChange}
        //                         className="add-visa-type-input yellow_border"
        //                         required
        //                         disabled={loading}
        //                     />
        //                 </div>
        //                 <div className='input-with-label'>
        //                     <label className='required'>Description</label>
        //                     <input
        //                         type="text"
        //                         name="typeDescription"
        //                         placeholder="Enter Type Description"
        //                         value={formData.typeDescription}
        //                         onChange={handleChange}
        //                         className="add-visa-type-input yellow_border"
        //                         required
        //                         disabled={loading}
        //                     />
        //                 </div>
        //             </div>
        //             <div className="clear-and-Add-button-container">
        //                 <button type="button" onClick={handleClear} className="clear-And-Add-Record-button" disabled={loading}>
        //                     Clear
        //                 </button>
        //                 <button type="submit" className="clear-And-Add-Record-button" disabled={loading}>
        //                     Add Record
        //                 </button>
        //             </div>
        //         </form>
        //     )}
        // </div>
    );
};

export default AddProductType;
