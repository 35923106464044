export const GET_ALL_PRODUCT_TYPE_REQUEST = "GET_ALL_PRODUCT_TYPE_REQUEST";
export const GET_ALL_PRODUCT_TYPE_SUCCESS = "GET_ALL_PRODUCT_TYPE_SUCCESS";
export const GET_ALL_PRODUCT_TYPE_FAIL = "GET_ALL_PRODUCT_TYPE_FAIL";

export const GET_ALL_PRODUCT_FROM_LOCATION_REQUEST = "GET_ALL_PRODUCT_FROM_LOCATION_REQUEST";
export const GET_ALL_PRODUCT_FROM_LOCATION_SUCCESS = "GET_ALL_PRODUCT__FROM_LOCATION_SUCCESS";
export const GET_ALL_PRODUCT_FROM_LOCATION_FAIL = "GET_ALL_PRODUCT_FROM_LOCATION_FAIL";

export const GET_ALL_PRODUCT_REQUEST = "GET_ALL_PRODUCT_REQUEST";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT__SUCCESS";
export const GET_ALL_PRODUCT_FAIL = "GET_ALL_PRODUCT_FAIL";

export const GET_PRODUCTTYPE_BYID_REQUEST = "GET_PRODUCTTYPE_BYID_REQUEST";
export const GET_PRODUCTTYPE_BYID_SUCCESS = "GET_PRODUCTTYPE_BYID_SUCCESS";
export const GET_PRODUCTTYPE_BYID_FAIL = "GET_PRODUCTTYPE_BYID_FAIL";


export const UPDATE_PRODUCTTYPESTATUS_BYID_REQUEST = "UPDATE_PRODUCTTYPESTATUS_BYID_REQUEST";
export const UPDATE_PRODUCTTYPESTATUS_BYID_SUCCESS = "UPDATE_PRODUCTTYPESTATUS_BYID_SUCCESS";
export const UPDATE_PRODUCTTYPESTATUS_BYID_FAIL = "UPDATE_PRODUCTTYPESTATUS_BYID_FAIL";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "GADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

export const ADD_PRODUCT_TYPE_REQUEST = "ADD_PRODUCT_TYPE_REQUEST";
export const ADD_PRODUCT_TYPE_SUCCESS = "GADD_PRODUCT_TYPE_SUCCESS";
export const ADD_PRODUCT_TYPE_FAIL = "ADD_PRODUCT_TYPE_FAIL";

export const CLEAR_ERROR="CLEAR_ERROR";
export const CLEAR_MESSAGE="CLEAR_MESSAGE";

