import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from '@mui/icons-material/Close';
import swal from "sweetalert2";
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { addProductQuantityThroughDemand, getDemandById, postReceivedQuantity,updateDemandStatus } from '../../../actions/demandAction';
import { getProductQuantity } from '../../../actions/requestAction';

const ReceiveModal = ({ isReceiveModalOpen, setIsReceiveModalOpen, product, request_id, isApproveModalOpen, setIsApproveModalOpen, locationId }) => {
    const alert = useAlert();
    // const {loading} =useSelector((state)=>state.demandReducer)
    // const { loading, quantity, message, error } = useSelector((state) => state.quantity)
    const dispatch = useDispatch();
    const [receiveInput, setReceiveInput] = useState('');

    useEffect(()=>{
        console.log('hfeli', isReceiveModalOpen)
    }, [isReceiveModalOpen])
    const handleInputChange = (e) => {
        const enteredValue = e.target.value;
        // if (enteredValue === '0') {
        //     setReceiveInput('');
        //     return;
        // }
        const newValue = Math.min(parseInt(enteredValue, 10), product?.quantity || 0);
        setReceiveInput(newValue.toString());
    }

    const handleReceiveSubmit = async (product_id) => {
        try {
            swal
            .fire({
              icon: "warning",
              title: "Are you sure you want to Update Record",
              showCancelButton: true,
              confirmButtonText: "yes",
              cancelButtonText: "no",
              customClass: {
                popup: "custom-swal-popup", // This is the custom class you're adding
              },
            })
            .then(async (result) => {
              if (result.value) {
                dispatch(postReceivedQuantity(request_id, product_id, receiveInput))
                const status = "Approved"
                dispatch(updateDemandStatus(request_id, status))
                const productId = product_id
                //  dispatch(updateProductQuantity(productId,remainingQuantity))
                dispatch(addProductQuantityThroughDemand(locationId, productId, receiveInput))
                setIsReceiveModalOpen(!isReceiveModalOpen)
                 setTimeout(() => {
                dispatch(getDemandById(request_id))
                 }, 500);
              }})
           

        } catch (error) {
            alert.error(error)
        }
    }
    const handleCloseClick = () => {
        setIsReceiveModalOpen(!isReceiveModalOpen)
    }
    useEffect(() => {
        // console.log(product);
        dispatch(getProductQuantity(product._id._id))
    }, [])

    return (
        <Fragment>
            {
                     <div className="modal">
                            <div className="modal-content">
                                <div className='buttonDiv'>
                                    <button onClick={handleCloseClick}><CloseIcon /></button>
                                </div>
                                <div className="headerHeading">
                                    <h2>Pir Mehr Ali Shah Arid Agriculture University Rawalpindi</h2>
                                    <p>University institute of information Technology </p>
                                </div>
                                <div className="contentt-box">
                                    <div className="heading-container">
                                        <h3>{"Update Demands"}</h3>
                                    </div>
                                </div>
                                <div className='form'>
                                    <div className="formRow">
                                    <div className="inputSection">
                                        <label>{"Product Name"}</label>
                                        <input
                                            type='text'
                                            value={product?._id.name}
                                            disabled
                                        />
                                    </div>
                                    <div className="inputSection">
                                        <label>{"Requested Quantity"}</label>
                                        <input
                                            type='number'
                                            value={product?.quantity}
                                            disabled
                                        />
                                    </div>
                                    </div> 
                                    <div className="formRow">
                                        <div className="inputSection">
                                            <label>{"Received Quantity"}</label>
                                            <input
                                                type='number'
                                                max={product?.quantity}
                                                min={0}
                                                onChange={handleInputChange}
                                                value={receiveInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="buttonRow">
                                        <button className='button button-back'
                                            onClick={() => { handleReceiveSubmit(product?._id._id) }}
                                            disabled={!receiveInput}
                                        >
                                        submit</button> 
                                    </div>
                                </div>
                          
                            </div>
                            </div>
                //     </>
                // )
            }
             
            {/* <Modal className='Modal'
                size='lg'
                isOpen={isReceiveModalOpen}
                toggle={handleCloseClick}
            >
                <ModalHeader >
                    <FontAwesomeIcon className='svg-icon' icon={faTimes} style={{ float: 'right', cursor: 'pointer' }} onClick={handleCloseClick} />
                </ModalHeader>
                <ModalBody>
                    <table className='modal-body-table'>
                        <thead>

                            <th>Name</th>
                            <th>Requested</th>
                            <th><label>Receive</label></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{product?._id.name}</td>
                                <td>{product?.quantity}</td>
                                <td>
                                    <input
                                        type='number'
                                        max={product?.quantity || 0}
                                        min={1}
                                        onChange={handleInputChange}
                                        value={receiveInput}
                                    >
                                    </input>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <div className='button-div'>
                        { <td>
                            <button className='submit_button'
                                onClick={() => { handleReceiveSubmit(product?._id._id) }}
                                disabled={!receiveInput}
                            >
                                submit</button>
                        </td>}
                    </div>
                </ModalBody>
            </Modal> */}
        </Fragment>
    )
}


export default ReceiveModal
