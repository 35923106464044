export const GET_PRODUCT_COUNT_REQUEST = "GET_PRODUCT_COUNT_REQUEST";
export const GET_PRODUCT_COUNT_SUCCESS = "GET_PRODUCT_COUNT_SUCCESS";
export const GET_PRODUCT_COUNT_FAIL = "GET_PRODUCT_COUNT_FAIL";

export const GET_WAITING_REQUEST_COUNT_REQUEST = "GET_WAITING_REQUEST_COUNT_REQUEST";
export const GET_WAITING_REQUEST_COUNT_SUCCESS = "GET_WAITING_REQUEST_COUNT_SUCCESS";
export const GET_WAITING_REQUEST_COUNT_FAIL = "GET_WAITING_REQUEST_COUNT_FAIL";

export const GET_7DAYS_PRODUCT_REQUEST_REQUEST = "GET_7DAYS_PRODUCT_REQUEST_REQUEST";
export const GET_7DAYS_PRODUCT_REQUEST_SUCCESS = "GET_7DAYS_PRODUCT_REQUEST_SUCCESS";
export const GET_7DAYS_PRODUCT_REQUEST_FAIL = "GET_7DAYS_PRODUCT_REQUEST_FAIL";

export const GET_7DAYS_USER_APPROVAL_REQUEST = "GET_7DAYS_USER_APPROVAL_REQUEST";
export const GET_7DAYS_USER_APPROVAL_SUCCESS = "GET_7DAYS_USER_APPROVAL_SUCCESS";
export const GET_7DAYS_USER_APPROVAL_FAIL = "GET_7DAYS_USER_APPROVAL_FAIL";

export const GET_TOTAL_USER_COUNT_REQUEST = "GET_TOTAL_USER_COUNT_REQUEST";
export const GET_TOTAL_USER_COUNT_SUCCESS = "GET_TOTAL_USER_COUNT_SUCCESS";
export const GET_TOTAL_USER_COUNT_FAIL = "GET_TOTAL_USER_COUNT_FAIL";

export const GET_TOTAL_ACTIVE_USER_COUNT_REQUEST = "GET_TOTAL_ACTIVE_USER_COUNT_REQUEST";
export const GET_TOTAL_ACTIVE_USER_COUNT_SUCCESS = "GET_TOTAL_ACTIVE_USER_COUNT_SUCCESS";
export const GET_TOTAL_ACTIVE_USER_COUNT_FAIL = "GET_TOTAL_ACTIVE_USER_COUNT_FAIL";

export const GET_TOTAL_ROLE_COUNT_REQUEST = "GET_TOTAL_ROLE_COUNT_REQUEST";
export const GET_TOTAL_ROLE_COUNT_SUCCESS = "GET_TOTAL_ROLE_COUNT_SUCCESS";
export const GET_TOTAL_ROLE_COUNT_FAIL = "GET_TOTAL_ROLE_COUNT_FAIL";

export const GET_USER_APPROVAL_REQUEST_REQUEST = "GET_USER_APPROVAL_REQUEST_REQUEST";
export const GET_USER_APPROVAL_REQUEST_SUCCESS = "GET_USER_APPROVAL_REQUEST_SUCCESS";
export const GET_USER_APPROVAL_REQUEST_FAIL = "GET_USER_APPROVAL_REQUEST_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_MESSAGE="CLEAR_MESSAGE"