import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { getRoleTasks, updateRoleTask, clearError } from '../../../actions/roleAction';
import Loader from '../../Loader/Loader';
import TablePagination from '@mui/material/TablePagination';
import ReactTable from '../../ReactTable'; // Ensure the path is correct
import TableComponentId from '../../Table Component/tableComponent';

const EditTask = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const alert = useAlert();
    const assignTask = "Assign Task"
    // const roleData = location.state ? location.state.roleData : null;
    const [task_id, setTask_id] = useState()
    const { allTask, loading, message, roleTasks, error } = useSelector((state) => state.role);
    const {updateRoleTasks, updateRoleTasksLoading} = useSelector((state)=> state.updateRoleTasks)
    const [searchTask, setSearchTask] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }
        // if (roleData) {
            dispatch(getRoleTasks(location.state.roleData._id));
        // }
    }, [dispatch, error, alert]);

    useEffect(() => {
        if (message) {
            alert.success(message);
        }
    }, [message]);

    const handleChangeCheckbox = (taskId, e) => {
        console.log(taskId, e)
        setTask_id(taskId)
        const roleId = location.state.roleData._id;
        const newStatus = e.target.checked;
        console.log(newStatus)
        dispatch(updateRoleTask(roleId, taskId, newStatus));
        // dispatch(getRoleTasks(roleId));
        
    };

    useEffect(()=>{
        if(updateRoleTasks?.length > 0 && !updateRoleTasksLoading)
        {
            dispatch(getRoleTasks(location.state.roleData._id));
        }
    }, [updateRoleTasksLoading, updateRoleTasks])

    const filteredTasks = roleTasks?.task_id?.filter(task => task?.task_id?.name.toLowerCase().includes(searchTask.toLowerCase())) || [];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const indexOfLastTask = page * rowsPerPage + rowsPerPage;
    const indexOfFirstTask = page * rowsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);

    // const columns = [
    //     {
    //         Header: 'S:No',
    //         accessor: (row, index) => indexOfFirstTask + index + 1
    //     },
    //     {
    //         Header: 'Task',
    //         accessor: 'task_id.name'
    //     },
    //     {
    //         Header: 'Operation',
    //         accessor: 'status',
    //         Cell: ({ row }) => (
    //             <input
    //                 type="checkbox"
    //                 checked={row.original.status}
    //                 onChange={(e) => handleChangeCheckbox(row.original.task_id._id, e)}
    //             />
    //         )
    //     }
    // ];

    const columns = [
        { field: "task_id.name", label: "Task" },
        { field: "task_id.type", label: "Category" },
        { field: "task_id.description", label: "Description" },
      ];

      const actions = [
        {
          label: "Assign Task",
          color: "yellow",
          handler: (itemId, e) => handleChangeCheckbox(itemId?._id, e),
        },
      ];   
    return (
        <Fragment>

        <div className={`Task`}>
        <div className="secondContainer">
            <div className="contentt-box">
                <div className="heading-container">
                    <h3>{`${location.state.roleData.name} Tasks`}</h3>
                    <h5>
                        <span className="total-records">
                            {"Total Records"}&nbsp;&nbsp;
                            <EventAvailableIcon fontSize="small" />
                        </span>
                        {/* <span className="rowCount">{rowCount}</span> */}
                    </h5>
                </div>
                <div className="excelDiv">
                <button  onClick={() => navigate('/roleTaskEdit')}>Back</button>
                    {/* {task ? <button onClick={handleAddTaskClick}>Add Task</button>: null} */}
                </div>
            </div>
            <div className="search-box">
            <input
                type="text"
                placeholder="Enter Task Name"
                value={searchTask}
                onChange={(e) => setSearchTask(e.target.value)}
            />
            
            </div>
            <div className="table-container">
                        {loading ? 
                            (
                                <Loader />
                            ) : 
                            (
                                <TableComponentId data={roleTasks?.task_id}
                                columns={columns}
                                actions={actions}
                                assignTask={assignTask}
                                task_id={task_id}
                                />
                            )
                        }
                    
            </div>
        </div>
    </div>
{/*     
            <div className='main-page-container'>
                {roleData ? (
                    <div>
                        <div className='pageName_And_Button'>
                            <h3>{`${roleData.name} tasks`}</h3>
                            <button className="button-yellow" onClick={() => navigate('/roleTaskEdit')}>Back</button>
                        </div>
                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Enter Task Name"
                                value={searchTask}
                                onChange={(e) => setSearchTask(e.target.value)}
                            />
                        </div>
                        <div className='table-container'>
                            {loading ? (
                                <Loader />
                            ) : (
                                <ReactTable data={currentTasks} columns={columns} />
                            )}
                        </div>
                        <TablePagination
                            component="div"
                            count={filteredTasks.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                ) : (
                    <p>No role data available.</p>
                )}
            </div> */}

        </Fragment>
    );
};

export default EditTask;
