import React from 'react'
import './Loader.css'
function Loader() {
  return (
    <div className="loading">
    <span className="pageLoader"></span>
  </div>
  )
}

export default Loader
