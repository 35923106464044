import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { updateRequestedProductStatus, clearError } from '../../../actions/requestAction.js';
import Loader from '../../Loader/Loader.js';
// import './CommentModal.css';

const CommentModal = ({ isCommentModalOpen, setIsCommentModalOpen, currentRequestId, currentProductId }) => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const {user} = useSelector((state)=> state.userData)
    const { loading, error } = useSelector((state) => state.requests);

    const [commentInput, setCommentInput] = useState('');

    const handleCommentChange = (e) => {
        setCommentInput(e.target.value);
    }

    const handleSubmit = async () => {
        try {
            const comment = commentInput;
            const status = 'rejected';
            const received_quantity = 0;
            console.log(currentRequestId, currentProductId)
            await dispatch(updateRequestedProductStatus(currentRequestId, currentProductId?._id, received_quantity, status, comment, user?.name));
            setIsCommentModalOpen(!isCommentModalOpen);
        } catch (error) {
            alert.error('Failed to reject request');
        }
    }

    const closeIconClick = () => {
        setIsCommentModalOpen(!isCommentModalOpen);
        setCommentInput('');
    }

    useEffect(() => {
        console.log(currentRequestId, currentProductId)
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }
    }, [error, alert, dispatch]);

    return (
        <>
          {loading ? (
                <div className="modal">
                <div className="modal-content">
                    <Loader />
                    </div></div>
            ) : (<>
                            <div className="modal">
                            <div className="modal-content">
                                <div className='buttonDiv'>
                                    <button onClick={closeIconClick}><CloseIcon /></button>
                                </div>
                                <div className='form'>
                                    <div className="formRow">
                                        <div className="inputSection">
                                        <label>{"Comment"}</label>
                                        <input
                                            type='text'
                                            id="comment"
                                            onChange={handleCommentChange}
                                            value={commentInput}
                                        />
                                        </div>
                                    </div>
                                    <div className='buttonRow'>
                                    <button
                                        className='button button-back'
                                        onClick={handleSubmit}
                                        disabled={commentInput === ''}
                                    >
                                        Submit
                                    </button>
                                    </div>
                                </div>
                                
                             
                             
                            </div>
                            </div>
            </>)}</>
        // <Fragment>
        //     <Modal className='comment-modal' size='lg' isOpen={isCommentModalOpen} toggle={closeIconClick}>
        //         <ModalHeader className='comment-modal-header'>
        //             <FontAwesomeIcon className='svg-icon' icon={faTimes} onClick={closeIconClick} />
        //         </ModalHeader>
        //         {loading ? (
        //             <div className="fullscreen-loader">
        //                 <Loader />
        //             </div>
        //         ) : (
        //             <ModalBody className='comment-modal-body'>
        //                 <div className="comment-section">
        //                     <label htmlFor="comment">Comment : </label>
        //                     <input
        //                         type='text'
        //                         id="comment"
        //                         onChange={handleCommentChange}
        //                         value={commentInput}
        //                     />
        //                 </div>
        //                 <div className='button-div'>
        //                     <button
        //                         className='submit_button'
        //                         onClick={handleSubmit}
        //                         disabled={commentInput === ''}
        //                     >
        //                         Submit
        //                     </button>
        //                 </div>

        //             </ModalBody>
        //         )}
        //     </Modal>
        // </Fragment>
    );
}

export default CommentModal;
