export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "GADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

// export const GET_ALL_ROLE_REQUEST = "GET_ALL_ROLE_REQUEST";
// export const GET_ALL_ROLE_SUCCESS = "GET_ALL_ROLE_SUCCESS";
// export const GET_ALL_ROLE_FAIL = "GET_ALL_ROLE_FAIL";

export const GET_ALL_DESIGNATION_REQUEST = "GET_ALL_DESIGNATION_REQUEST";
export const GET_ALL_DESIGNATION_SUCCESS = "GET_ALL_DESIGNATION_SUCCESS";
export const GET_ALL_DESIGNATION_FAIL = "GET_ALL_DESIGNATION_FAIL";

export const GET_ALL_USER_REQUEST = "GET_ALL_USER_REQUEST";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const GET_ALL_USER_FAIL = "GET_ALL_USER_FAIL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAIL = "UPDATE_USER_STATUS_FAIL";

export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CLEAR_ERROR = "CLEAR_ERROR";