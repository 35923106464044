export const GET_ALL_COMPANY_REQUEST = "GET_ALL_COMPANY_REQUEST";
export const GET_ALL_COMPANY_SUCCESS = "GET_ALL_COMPANY_SUCCESS";
export const GET_ALL_COMPANY_FAIL = "GET_ALL_COMPANY_FAIL";
export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";

export const GET_COMPANY_BY_ID_REQUEST = "GET_COMPANY_BY_ID_REQUEST";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_FAIL = "GET_COMPANY_BY_ID_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_MESSAGE="CLEAR_MESSAGE"