import {
    GET_ALL_COMPANY_REQUEST,
    GET_ALL_COMPANY_SUCCESS,
    GET_ALL_COMPANY_FAIL,
    ADD_COMPANY_REQUEST,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_FAIL,
    CLEAR_ERROR,
    CLEAR_MESSAGE,
    GET_COMPANY_BY_ID_REQUEST,
    GET_COMPANY_BY_ID_SUCCESS,
    GET_COMPANY_BY_ID_FAIL,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL
} from '../Redux/constants/companyConstants'

import axiosInstance from '../axiosInstance/axiosInstance'
let config = {
  headers: { 'Content-Type': 'application/json' }
}

export const getCompanyById = (id) => async (dispatch) => {
    // console.log('enter the get');
      try {
        dispatch({ type: GET_COMPANY_BY_ID_REQUEST });
    
        const response = await axiosInstance.get(`/product/getCompanyRecordById/${id}`);
        // console.log(response.data);
        dispatch({ type: GET_COMPANY_BY_ID_SUCCESS, payload: response.data });
      } catch (error) {
        console.log(error);
        dispatch({ type: GET_COMPANY_BY_ID_FAIL, payload: error.message });
      }
    };

    export const getAllCompany  = () => async (dispatch) => {
      // console.log('enter the get');
        try {
          dispatch({ type: GET_ALL_COMPANY_REQUEST });
      
          const response = await axiosInstance.get(`/product/getProductCompany`);
          // console.log(response.data);
          dispatch({ type: GET_ALL_COMPANY_SUCCESS, payload: response.data.product });
        } catch (error) {
          console.log(error);
          dispatch({ type: GET_ALL_COMPANY_FAIL, payload: error.message });
        }
      };
export const AddCompany = (myForm) => async (dispatch) => {
    // console.log('enter the get');
      try {
        dispatch({ type: ADD_COMPANY_REQUEST });
    
        const response = await axiosInstance.post(`/product/addProductCompany`,myForm,config);
        // console.log(response.data);
        dispatch({ type: ADD_COMPANY_SUCCESS, payload: response.data.message });
      } catch (error) {
        console.log(error);
        dispatch({ type: ADD_COMPANY_FAIL, payload: error.message });
      }
    };

    export const updateCompanyRecord = (id, name, description) => async (dispatch) => {
      // console.log('enter the get');
        try {
          dispatch({ type: UPDATE_COMPANY_REQUEST });
          console.log(id, name, description)
          const response = await axiosInstance.put(`/product/updateProductCompany/${id}`, {name, description});
          // console.log(response.data);
          dispatch({ type: UPDATE_COMPANY_SUCCESS, payload: response.data });
        } catch (error) {
          console.log(error);
          dispatch({ type: UPDATE_COMPANY_FAIL, payload: error.message });
        }
      };