import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateRole } from '../../actions/registrationApprovalAction';
import { useAlert } from 'react-alert';
let typeAssigned= []
const ApprovalModel = ({userId, userStatus, setUserStatus, userApprovalModel, setUserApprovalModel, productType_id, setProductTypeId}) => {
    const dispatch = useDispatch();
    const alert = useAlert()
    const [approved, setApproved] = useState()
    const [disapproved, setDisapproved] = useState()
    const [roleId, setRoleId] = useState()
    const { allRole } = useSelector((state) => state.role);

    

    const handleApproved = (e) => {
    console.log(e.target.checked)
    setApproved(e.target.checked)
    setDisapproved(!e.target.checked)
    setUserStatus("")
    };

    const handleDisapproved = (e) => {
        console.log(e.target.checked)
        setRoleId('')
        setApproved(!e.target.checked)
        setDisapproved(e.target.checked)
        };
        
    const handleDropdownChange = (e) => {
        console.log(e.target.value)
        setRoleId(e.target.value)
    }
    

    const handleApply = () => {
        // const roleId = selectedRoles[userId._id];

        if (disapproved) {
            dispatch(updateRole(userId, roleId, 'Rejected'));
        } else if(roleId) {

            dispatch(updateRole(userId, roleId, "Active"));
          
        }else{
            alert.error('Please select a role before submitting.');
        }
        setUserApprovalModel(!userApprovalModel);
    }

    const clickCloseIcon = () => {
    
        setUserApprovalModel(!userApprovalModel);
    };


    const columns = [
        { field: "_id.name", label: "Role Name" },
      ];

      const actions = [
        {
          label: "ProductTypeAssignedRole",
          color: "yellow",
        //   handler: (itemId, e) => handleRecord(itemId, e),
        },
   
      ];

    return (
        <Fragment>
         
                            <div className="modal">
                            <div className="modal-content">
                                <div className='buttonDiv'>
                                    <button onClick={clickCloseIcon}><CloseIcon /></button>
                                </div>
                                <div className='contentt-box'>
                                    <div className='heading-container'>
                                        <h3>Update User Approval</h3>
                                    </div>
                                </div>
                                <div className='form'>
                                    <div className='formRow'>
                                        <div className='checkboxSection'>
                                            
                                            <input
                                                type='checkbox'
                                                onChange={handleApproved}
                                                checked={approved}
                                            />  <label>Approved</label>
                                        </div><div className='checkboxSection'>
                                            
                                            <input
                                                type='checkBox'
                                                onChange={handleDisapproved}
                                                checked={disapproved || userStatus === "Rejected"}
                                            />  <label>Disapproved</label>
                                        </div>
                                    </div>
                                    <div className='formRow'>
                                        {
                                            approved && (  <div className='inputSection'>
                                                <label>Select Role</label>
                                                <select
                                                className='select-role'
                                                onChange={
                                                handleDropdownChange
                                                }
                                            >
                                                <option value="">Select role</option>
                                                {allRole
                                                    .filter(role => role.name !== 'Super Admin') // Exclude 'superAdmin' from dropdown
                                                        .map((role) => (
                                                            <option key={role._id} value={role._id}>
                                                                {role.name}
                                                            </option>
                                                    ))
                                                }
                                            </select>
                                            </div>)
                                        }
                                      
                                        
                                        
                                    </div>
                                    <div className='buttonRow'>
                                    <button className='button button-add-product' onClick={handleApply}>
                                            Update
                                        </button>
                                    </div>

                                </div>
                           
                           
                            </div>
                            </div>
           
           
  
        </Fragment>
    );
};

export default ApprovalModel;
