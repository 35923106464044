import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
// import {
//   clearError,
//   clearMessage,
//   getAllRole,
// } from "../../../actions/roleAction";
import { useNavigate } from "react-router-dom";
import ReactTable from "../../ReactTable"; // Make sure to import the ReactTable component
import Loader from "../../Loader/Loader";
import { getAllRole } from "../../../actions/roleAction";
import TableComponentId from "../../Table Component/tableComponent";

const Role = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchName, setSearchName] = useState("");
  const [searchDescription, setSearchDescription] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { loading, message, error, allRole } = useSelector(
    (state) => state.role
  );

  const filteredRoles = allRole?.filter(
    (role) =>
      role.name.toLowerCase().includes(searchName.toLowerCase()) &&
      role.description.toLowerCase().includes(searchDescription.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // useEffect(() => {
  //   if (error) {
  //     alert.error(error);
  //     return () => dispatch(clearError());
  //   }
  //   if (message) {
  //     alert.message(message);
  //     return () => dispatch(clearMessage());
  //   }
  //   // dispatch(getAllRole());
  // }, [dispatch, error, message]);
// const getAllRole =()=>{
//   console.log('jdlfi')
// }
  useEffect(()=>{
    // getAllRole()
    dispatch(getAllRole());
  }, [])

  const indexOfLastCustomer = page * rowsPerPage + rowsPerPage;
  const indexOfFirstCustomer = page * rowsPerPage;
  const currentRoles = filteredRoles?.slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );

  const handleAddRoleClick = () => {
    navigate("/addrole");
  };


  const columns = [
    { field: "name", label: "Name" },
    { field: "Description", label: "description" },
  ];


   
  // const actions = [
  //   {
  //     label: "View Details",
  //     color: "yellow",
  //     handler: (itemId) => handleViewButtonClick(itemId),
  //   },
  // ];      

  const { roleTask } = useSelector((state) => state.userData);
  var task = false;
  task = roleTask.find((e) => e?.task_id?.name === "name" && e.status === true);

  return (
    <>
    <div className={`Role`}>
      <div className="secondContainer">
        <div className="contentt-box">
          <div className="heading-container">
            <h3>{"Role"}</h3>
              <h5>
                <span className="total-records">
                  {"Total Records"}&nbsp;&nbsp;
                  <EventAvailableIcon fontSize="small" />
                </span>
                {/* <span className="rowCount">{rowCount}</span> */}
              </h5>
          </div>
          <div className="excelDiv">
                <button  onClick={() => navigate('/roleTaskEdit')}>Back</button>
                    {/* {task ? <button onClick={handleAddTaskClick}>Add Task</button>: null} */}
                </div>
        </div>
        <div className="search-box">
          <input
            type="text"
            placeholder="Enter Role Name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter Description"
            value={searchDescription}
            onChange={(e) => setSearchDescription(e.target.value)}
          />
        </div>
        <div className="table-container">
          {loading ? (
            <Loader />
          ) : (
            <TableComponentId data={currentRoles} columns={columns} />
          )}
        </div>
      </div>
    </div>
      {/* <div className="main-page-container">
        <div className="pageName_And_Button">
          <h2>Role</h2>

          {task ? (
            <button className="button-yellow" onClick={handleAddRoleClick}>
              Add Role
            </button>
          ) : null}
        </div>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Enter Role Name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter Description"
            value={searchDescription}
            onChange={(e) => setSearchDescription(e.target.value)}
          />
        </div>
        <div className="table-container">
          {loading ? (
            <Loader />
          ) : (
            <ReactTable data={currentRoles} columns={columns} />
          )}
        </div>
        <TablePagination
          component="div"
          count={filteredRoles?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div> */}
    </>
  );
};

export default Role;
