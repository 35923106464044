import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import swal from "sweetalert2";
import CloseIcon from '@mui/icons-material/Close';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { getProductQuantity, updateProductQuantity, updateRequestedProductStatus, clearError } from '../../../actions/requestAction.js';
// import './AssignModal.css'
import Loader from '../../Loader/Loader.js';

const AssignModal = ({ isAssignModalOpen, setIsAssignModalOpen, requestItems, currentRequestId, requestedProduct, event }) => {
    const dispatch = useDispatch();
    const product_id = requestItems?._id._id;
    const alert = useAlert();
    const { quantity, loading, error } = useSelector((state) => state.quantity);
    const {updateRequestsStatus, updateRequestsStatusLoading, updateRequestsStatusError} = useSelector((state)=> state.updateRequestsStatus)
    const {user} = useSelector((state)=> state.userData)
    const productAvailableQuantity = quantity;

    const [assignInput, setAssignInput] = useState('');
    const [commentInput, setCommentInput] = useState('');

    const handleInputChange = (e) => {
        // console.log(e.target.value);
        const enteredValue = e.target.value;
        if (enteredValue === '0') {
            setAssignInput('');
            return;
        } else if (enteredValue === '') {
            setAssignInput('')
            return
        } {

        }
        const newValue = Math.min(parseInt(enteredValue, 10), productAvailableQuantity || 0);
        setAssignInput(newValue.toString());
    }

    const handleAssignSubmit = async () => {
        let remainingQuantity;
        const received_quantity = assignInput;
        try {
            if (event) {
                const editedQuantity = requestItems?.received_quantity - assignInput
                remainingQuantity = productAvailableQuantity + editedQuantity;
            } else {
                remainingQuantity = productAvailableQuantity - received_quantity;
            }
            const status = 'assigned';
            const comment = commentInput    
            console.log(currentRequestId, product_id, received_quantity, status, comment)
            swal
            .fire({
              icon: "warning",
              title: "Are You Sure You Want To Submit",
              showCancelButton: true,
              cancelButtonText: "No",
              confirmButtonText: "Yes",
            
              customClass: {
                popup: 'popup-desing',
                title: 'swal2-title-custom',
                content: 'swal2-content-custom'
              }
            })
            .then(async (result) => {
              if (result.value) {
                dispatch(updateRequestedProductStatus(currentRequestId, product_id, received_quantity, status, comment, user?.name));
                dispatch(updateProductQuantity(product_id, remainingQuantity));
    
                setIsAssignModalOpen(!isAssignModalOpen);
              }})
           

        } catch (error) {
            alert.error(error);
        }
    }

    useEffect(()=>{
        console.log(updateRequestsStatus, updateRequestsStatusLoading)
    }, [updateRequestsStatus, updateRequestsStatusLoading])

    const closeIconClick = () => {
        setIsAssignModalOpen(!isAssignModalOpen);
        setAssignInput('');
    }
    const handleCommentChange = (e) => {
        setCommentInput(e.target.value)
    }


    useEffect(()=>{
        console.log(requestItems)
    }, [])
    // useEffect(() => {
    //     if (error) {
    //         alert.error(error);
    //         return () => dispatch(clearError());
    //     }
    //     dispatch(getProductQuantity(product_id));
    // }, [error, requestItems]);

    useEffect(() => {
        // if (requestItems) {
            setAssignInput(requestItems.received_quantity?.toString() || '');
            setCommentInput(requestItems.comment);
        // }
    }, [requestItems]);

    return (
        <Fragment>
            <div className='modal'>
                <div className='modal-content'>
                {loading ? (<></>):(
                    <>
                    <div className='buttonDiv'>
                        <button onClick={closeIconClick}><CloseIcon /></button>
                    </div>
                    <div className='form'>
                            <div className="formRow">
                                <div className="inputSection">
                                    <label>{"Requested Quantity"}</label>
                                    <input
                                            type='number'
                                            max={requestItems?.requested_quantity || 0}
                                            min={1}
                                            value={requestItems?.requested_quantity}
                                            disabled
                                        />
                                    </div>
                                    <div className="inputSection">
                                    <label>{"Available Quantity"}</label>
                                    <input
                                            type='number'
                                            max={requestItems?.requested_quantity || 0}
                                            min={1}
                                            value={productAvailableQuantity}
                                            disabled
                                        />
                                </div>
                                </div>
                                <div className="formRow">
                                <div className="inputSection">
                                    <label>{"Status"}</label>
                                    <input
                                            type='text'
                                            
                                            value={requestItems?.status
                                            }
                                            disabled
                                        />
                                      </div>
                                    <div className="inputSection">
                                    <label>  {
                                        event ? (
                                         "Received"
                                        ) : (
                                            "Assign"
                                        )
                                    }</label>
                                    <input
                                            type='number'
                                            max={productAvailableQuantity }
                                            min={1}
                                            onChange={handleInputChange}
                                            value={assignInput}
                                            disabled={productAvailableQuantity < 1}
                                        />
                                  
                                </div>
                                </div>
                                <div className="formRow">
                            <div className="inputSection">
                                    <label>{"Comments"}</label>
                                    <input
                                            max={requestItems?.requested_quantity || 0}
                                            min={1}
                                            type='text'
                                            onChange={handleCommentChange}
                                            value={commentInput}
                                        />
                                </div>
                                </div>
                   
                        <div className='buttonRow'>
                            <button
                                className='button button-back'
                                onClick={handleAssignSubmit}
                                disabled={assignInput === '' || parseInt(assignInput, 10) > requestItems?.requested_quantity}
                            >
                                Submit
                            </button>
                        </div>

                        </div>
                    </>
                )}
                </div>
            </div>
            {/* <Modal className='Modal' size='lg' isOpen={isAssignModalOpen} toggle={() => setIsAssignModalOpen(!isAssignModalOpen)}>
                {loading ? (
                     <div className="main_container_assignModal"> 
                      <ModalHeader>
                      <FontAwesomeIcon className='svg-icon' icon={faTimes} style={{ float: 'right', cursor: 'pointer' }} onClick={closeIconClick} />

                          <FontAwesomeIcon className='svg-icon' icon={faTimes} style={{ float: 'right', cursor: 'pointer' }} onClick={closeIconClick} />
                        <Loader />
                    </ModalHeader>
                    </div> 
                ) : (
                    <>
                <ModalHeader>
                    <FontAwesomeIcon className='svg-icon' icon={faTimes} style={{ float: 'right', cursor: 'pointer' }} onClick={closeIconClick} />
                </ModalHeader>
                    <ModalBody >
                       

                    </ModalBody>
                    </>
                    )}
            </Modal> */}
        </Fragment>
    )
}

export default AssignModal;
