export const GET_ALL_REGISTRATION_APPROVAL_REQUEST = "GET_ALL_REGISTRATION_APPROVAL_REQUEST";
export const GET_ALL_REGISTRATION_APPROVAL_SUCCESS = "GET_ALL_REGISTRATION_APPROVAL_SUCCESS";
export const GET_ALL_REGISTRATION_APPROVAL_FAIL = "GET_ALL_REGISTRATION_APPROVAL_FAIL";


export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_MESSAGE="CLEAR_MESSAGE"