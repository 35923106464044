import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import TableComponentId from '../../Table Component/tableComponent';
import { getAllRole } from '../../../actions/roleAction';
import { getProductTypeById, updateProductTypeStatus } from '../../../actions/productAction';
let typeAssigned= []
const UpdateRoleTypeModal = ({ openAssignModal, setOpenAssignModal, productType_id, setProductTypeId}) => {
    const dispatch = useDispatch();
    const [isReceiveModalOpen, setIsReceiveModalOpen] = useState(false);
    const [product, setProduct] = useState([]);
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [companyName, setCompanyName] = useState()
    const [companyDescription, setCompanyDescription] = useState()
    const assignProductTypeRole = "ProductTypeAssignedRole"
    const { producTypeById, producTypeByIdLoading } = useSelector((state) => state.productTypeById);
    const {producTypeStatus, producTypeStatusLoading} = useSelector((state)=> state.producTypeStatus)

    useEffect(()=>{
        if(!producTypeStatusLoading && producTypeStatus?.success)
        {
            dispatch(getProductTypeById(productType_id));
        }
    }, [producTypeStatus, producTypeStatusLoading])

    useEffect(()=>{
        if(producTypeById?.length > 0)
        {
            const filterData = producTypeById?.assignToRole?.filter((type) => type?._id?.name !== "Super Admin")
            console.log(filterData)
            setData(filterData)
        }
    }, [producTypeById, producTypeByIdLoading])

    useEffect(()=>{
        console.log(productType_id)
        // getAllRole()
        dispatch(getProductTypeById(productType_id));
      }, [productType_id, openAssignModal])

      useEffect(()=>{
        const filterData = producTypeById?.assignToRole?.filter((type) => type?._id?.name !== "Super Admin")
        console.log(filterData)
        setData(filterData)
        console.log(producTypeById) 
      }, [producTypeById, producTypeByIdLoading])
    

    const handleRecord = (data, e) => {
        console.log(data._id?._id, e.target.checked)
        dispatch(updateProductTypeStatus(productType_id, data._id._id, e.target.checked))
    };

    const handleApply = () => {
        // setAssignToRole(typeAssigned)
       
        setOpenAssignModal(!openAssignModal)
    }

    const clickCloseIcon = () => {
    
        setOpenAssignModal(!openAssignModal);
    };


    const columns = [
        { field: "_id.name", label: "Role Name" },
      ];

      const actions = [
        {
          label: "ProductTypeAssignedRole",
          color: "yellow",
          handler: (itemId, e) => handleRecord(itemId, e),
        },
   
      ];

    return (
        <Fragment>
            {producTypeByIdLoading &&  !producTypeStatusLoading? (
                <div className="modal">
                <div className="modal-content">
                    <Loader />
                    </div></div>
            ) : (<>
                            <div className="modal">
                            <div className="modal-content">
                                <div className='buttonDiv'>
                                    <button onClick={clickCloseIcon}><CloseIcon /></button>
                                </div>
                                <div className='search-box'></div>
                              
                            <div className="table-container">
                                {/* <div className="View_Modal_Table_wrapper"> */}
                                    <></>
                                    <TableComponentId
                                        data={data}
                                        columns={columns}
                                        actions={actions}
                                        assignProductTypeRole={assignProductTypeRole}
                                    />
                                 
                                {/* </div> */}
                            </div>
                           
                            </div>
                            </div>
            </>)}
           
  
        </Fragment>
    );
};

export default UpdateRoleTypeModal;
