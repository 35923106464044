export const GET_ALL_REQUEST_REQUEST = "GET_ALL_REQUEST_REQUEST";
export const GET_ALL_REQUEST_SUCCESS = "GET_ALL_REQUEST_SUCCESS";
export const GET_ALL_REQUEST_FAIL = "GET_ALL_REQUEST_FAIL";

export const GET_PRODUCT_QUANTITY_REQUEST = "GET_PRODUCT_QUANTITY_REQUEST";
export const GET_PRODUCT_QUANTITY_SUCCESS = "GET_PRODUCT_QUANTITY_SUCCESS";
export const GET_PRODUCT_QUANTITY_FAIL = "GET_PRODUCT_QUANTITY_FAIL";

export const UPDATE_PRODUCT_QUANTITY_REQUEST = "UPDATE_PRODUCT_QUANTITY_REQUEST";
export const UPDATE_PRODUCT_QUANTITY_SUCCESS = "UPDATE_PRODUCT_QUANTITY_SUCCESS";
export const UPDATE_PRODUCT_QUANTITY_FAIL = "UPDATE_PRODUCT_QUANTITY_FAIL";

export const UPDATE_REQUESTED_PRODUCT_STATUS_REQUEST = "UPDATE_REQUESTED_PRODUCT_STATUS_REQUEST";
export const UPDATE_REQUESTED_PRODUCT_STATUS_SUCCESS = "UPDATE_REQUESTED_PRODUCT_STATUS_SUCCESS";
export const UPDATE_REQUESTED_PRODUCT_STATUS_FAIL = "UPDATE_REQUESTED_PRODUCT_STATUS_FAIL";

export const UPDATE_REQUEST_STATUS_REQUEST = "UPDATE_REQUEST_STATUS_REQUEST";
export const UPDATE_REQUEST_STATUS_SUCCESS = "UPDATE_REQUEST_STATUS_SUCCESS";
export const UPDATE_REQUEST_STATUS_FAIL = "UPDATE_REQUEST_STATUS_FAIL";

export const REQUESTED_PRODUCT_REQUEST = "REQUESTED_PRODUCT_REQUEST";
export const REQUESTED_PRODUCT_SUCCESS = "REQUESTED_PRODUCT_SUCCESS";
export const REQUESTED_PRODUCT_FAIL = "REQUESTED_PRODUCT_FAIL";

export const GET_REQUEST_BY_ID_REQUEST = "GET_REQUEST_BY_ID_REQUEST";
export const GET_REQUEST_BY_ID_SUCCESS = "GET_REQUEST_BY_ID_SUCCESS";
export const GET_REQUEST_BY_ID_FAIL = "GET_REQUEST_BY_ID_FAIL";

export const CLEAR_ERROR='CLEAR_ERROR';
export const CLEAR_MESSAGE="CLEAR_MESSAGE";
