import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { clearError, getAllUser, updateUserRecord, updateUserStatus } from '../../actions/userAction';
import Loader from '../Loader/Loader';
import Switch from 'react-switch';
import ReactTable from '../ReactTable';
import TableComponentId from '../Table Component/tableComponent';
import UpdateUserModal from '../Modal/UpdateUserModal/UpdateUserModal';
// import './UserStatus.css';

const UserStatus = () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    // const { loading, allUser, error } = useSelector((state) => state.user);
    const { loading, allUserData, error } = useSelector((state) => state.allUser);
    const { user } = useSelector((state) => state.userData);
    const userStatus = "View User Status"
    const editUser = "Edit User"
    const [searchName, setSearchName] = useState('');
    const [searchDesignation, setSearchDesignation] = useState('');
    const [searchRole, setSearchRole] = useState('');
    const [inputName, setInputName] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [inputPhone_no, setInputPhone_no] = useState('');
    const [editingUser, setEditingUser] = useState(null);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const {updateUserData, updateUserDataLoading} = useSelector((state)=> state.updateUserData)
    const [searchEmail, setSearchEmail] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearError());
        } else {
            // dispatch(getAllUser());
        }
    }, [dispatch, alert, error]);


    const editEntry = (element) => {
        setInputName(element.name);
        setInputEmail(element.email);
        setInputPhone_no(element.phone_no);
        setEditingUser(element._id);
        setIsUpdateModalOpen(true);
      };

      const handleChangeName = (val) => {
        setInputName(val.target.value);
      };
    
      const handleChangeEmail = (val) => {
        setInputEmail(val.target.value);
      };
    
      const handleChangePhone_no = (val) => {
        setInputPhone_no(val.target.value);
      };

      const handleUpdate = (updatedUserData) => {
        // Dispatch action to update user
        dispatch(updateUserRecord(editingUser, updatedUserData))
          .then(() => {
            setIsUpdateModalOpen(false); 
            dispatch(getAllUser());// Close modal on successful update
            // alert.success('User updated successfully');
          })
          .catch((error) => {
            alert.error('Failed to update user');
          });
      };

    useEffect(()=>{
        if(updateUserData?.message === "Updated Successfully" && !updateUserDataLoading)
        {
            dispatch(getAllUser())
        }
    }, [updateUserData, updateUserDataLoading])
    

    useEffect(()=>{
        dispatch(getAllUser());
    }, [])

    
    const toggleStatus = (item) => {
        console.log(item)
        const status = item?.status === "Active" ? false : true
        // const status = !currentStatus;
        console.log(item,status)
        dispatch(updateUserStatus(item?._id, status));
    };

    const filteredUsers = useMemo(() => {
        return allUserData?.filter((user) =>
            user.name.toLowerCase().includes(searchName.toLowerCase()) &&
            user.designation_id?.name.toLowerCase().includes(searchDesignation.toLowerCase()) &&
            user.role_id?.name.toLowerCase().includes(searchRole.toLowerCase())
        );
    }, [searchName, searchDesignation, searchRole, allUserData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const columns = useMemo(() => [
    //     {
    //         Header: 'SrNo',
    //         accessor: (row, index) => page * rowsPerPage + index + 1,
    //     },
    //     {
    //         Header: 'Name',
    //         accessor: 'name',
    //     },
    //     {
    //         Header: 'Designation',
    //         accessor: 'designation_id.name',
    //     },
    //     {
    //         Header: 'Role',
    //         accessor: 'role_id.name',
    //     },
    //     {
    //         Header: 'Status',
    //         accessor: 'status',
    //         Cell: ({ row }) => {
    //             const record = row.original;
            //     if (user.role_id.name === 'admin') {
            //         return (
            //             record._id !== user._id && record.role_id.name !== 'SuperAdmin' && (
            //                 <Switch
            //                     onChange={() => toggleStatus(record._id, record.status)}
            //                     checked={record.status}
            //                     onColor="#0033A0"
            //                 />
            //             )
            //         );
            //     } else {
            //         return (
                        
            //                 <Switch
            //                     onChange={() => toggleStatus(record._id, record.status)}
            //                     checked={record.status}
            //                     onColor="#0033A0"
            //                     disabled={record._id == user._id || record.role_id.name == 'SuperAdmin'}
            //                 />
            //         );
            //     }
            // },
    //     },
    // ], [page, rowsPerPage, toggleStatus, user._id]);


    const columns = [
        { field: "name", label: "User" },
        { field: "email", label: "Email" },
        { field: "phone_no", label: "Phone No"},
        { field: "designation_id.name", label: "Designation" },
        { field: "role_id.name", label: "Role" },
      ];


       
      const actions = [
        {
          label: "View User Status",
          color: "yellow",
          handler: (itemId) => toggleStatus(itemId),
        },
        {
            label: "Edit User",
            color: "yellow",
            handler: (itemId) => editEntry(itemId),
          },
      ];     

    const data = useMemo(() => filteredUsers || [], [filteredUsers]);

    return (
        <Fragment>
                 <div className={`User`}>
                <div className="secondContainer">
                    <div className="contentt-box">
                        <div className="heading-container">
                            <h3>{"User Status"}</h3>
                            <h5>
                                <span className="total-records">
                                    {"Total Records"}&nbsp;&nbsp;
                                    <EventAvailableIcon fontSize="small" />
                                </span>
                                {/* <span className="rowCount">{rowCount}</span> */}
                            </h5>
                        </div>
                    </div>
                    <div className="search-box">
                    <input
                            type="text"
                            placeholder="Search Name"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Search Designation"
                            value={searchDesignation}
                            onChange={(e) => setSearchDesignation(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Search Role"
                            value={searchRole}
                            onChange={(e) => setSearchRole(e.target.value)}
                        />
                    </div>
                    <div className="table-container">
                                {loading ? 
                                    (
                                        <Loader />
                                    ) : 
                                    (<>
                                    
                                 <TableComponentId data={data}
                                        columns={columns}
                                        actions={actions}
                                        userStatus={userStatus}
                                        editUser={editUser}/>
                                    </>
                                     
                                    )
                                }
                    </div>
                </div>
                {
                isUpdateModalOpen && <UpdateUserModal
            isUpdateModalOpen={isUpdateModalOpen}
            setIsUpdateModalOpen={setIsUpdateModalOpen}
            inputName={inputName}
            inputEmail={inputEmail}
            inputPhone_no={inputPhone_no}
            handleChangeName={handleChangeName}
            handleChangeEmail={handleChangeEmail}
            handleChangePhone_no={handleChangePhone_no}
            handleUpdate={handleUpdate}
          />
        }

            </div>
                {/* <div className='main-page-container'>
                    <div className='pageName_And_Button'>
                        <h2>User Status</h2>
                    </div>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search Name"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Search Designation"
                            value={searchDesignation}
                            onChange={(e) => setSearchDesignation(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Search Role"
                            value={searchRole}
                            onChange={(e) => setSearchRole(e.target.value)}
                        />
                    </div>
                    <div className='table-container'>
                    {loading ? (
                <Loader />
            ) : (
                        <ReactTable
                            columns={columns}
                            data={data}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                    </div>
                </div>
             */}
        </Fragment>
    );
};

export default UserStatus;
