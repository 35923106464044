import {
    GET_ALL_COMPANY_REQUEST,
    GET_ALL_COMPANY_SUCCESS,
    GET_ALL_COMPANY_FAIL,
    ADD_COMPANY_REQUEST,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_FAIL,
    CLEAR_MESSAGE,
    CLEAR_ERROR,
    GET_COMPANY_BY_ID_REQUEST,
    GET_COMPANY_BY_ID_SUCCESS,
    GET_COMPANY_BY_ID_FAIL,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL
} from '../constants/companyConstants'

export const companyReducer = (state = {message:''}, action) => {
    switch (action.type) {
      case GET_ALL_COMPANY_REQUEST:
        case ADD_COMPANY_REQUEST:
        return {
          loading: true,
        };
      case GET_ALL_COMPANY_SUCCESS:
        return {
          loading: false,
          allCompany: action.payload,
        };
      case ADD_COMPANY_SUCCESS:
        return {
          loading: false,
          message: action.payload,
        };
      case GET_ALL_COMPANY_FAIL:
      case ADD_COMPANY_FAIL:
        // console.log(action.payload);
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERROR:
        return {
          ...state,
          error: null
        }
      case CLEAR_MESSAGE:
        return {
          ...state,
          message: null,
        }
      default:
        return state;
    }
};

export const getCompanyByIdReducer = (state = {companyById:[]}, action) => {
  switch (action.type) {
    case GET_COMPANY_BY_ID_REQUEST:
      return {
        companyByIdLoading: true,
      };
    case GET_COMPANY_BY_ID_SUCCESS:
      return {
        companyByIdLoading: false,
        companyById: action.payload,
      };
  
    case GET_COMPANY_BY_ID_FAIL:
  
      // console.log(action.payload);
      return {
        companyByIdLoading: false,
        companyByIdError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        companyByIdError: null
      }
    default:
      return state;
  }
};

export const updateCompanyReducer = (state = {updateCompany:[]}, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_REQUEST:
      return {
        updateCompanyLoading: true,
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        updateCompanyLoading: false,
        updateCompany: action.payload,
      };
  
    case UPDATE_COMPANY_FAIL:
  
      // console.log(action.payload);
      return {
        updateCompanyLoading: false,
        updateCompanyError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        updateCompanyError: null
      }
    default:
      return state;
  }
};