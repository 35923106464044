import React from 'react'
import ViewDemand from './ViewDemand'

function ViewDemandPage() {
  return (
    // <div style={{width:'100%', height:'100%'}}>
      <ViewDemand/>
    // </div>
  )
}

export default ViewDemandPage
