import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError, getAllSignatureRecord, updateSignatureRecordStatus, updateSignatureStatus } from "../../../actions/signatureRecordAction";
import Loader from '../../Loader/Loader';
import Switch from 'react-switch';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import './ViewSignatureRecord.css';
import { useAlert } from "react-alert";
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from "react-router-dom";
import ReactTable from '../../ReactTable'; // Adjust the path as needed
import TableComponentId from "../../Table Component/tableComponent";

export const ViewSignatureRecord = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { allSignatureRecord, loading, error } = useSelector((state) => state.signatureRecord);
  const {updateSignatureRecord, updateSignatureRecordLoading} = useSelector((state)=> state.updateSignatureRecord)
  // console.log(allSignatureRecord);
  const signatureToggle = 'Signature Toggle'
  const [searchName, setSearchName] = useState('');
  const [searchDesignation, setSearchDesignation] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearError());
    }
    dispatch(getAllSignatureRecord());
  }, [dispatch, error, alert]);

  const toggleStatus = async (id, currentStatus,allSignatureRecord) => {
    try {
      console.log(id, currentStatus)
      dispatch(updateSignatureStatus(id,  currentStatus));
    } catch (error) {
      console.error("Error updating signature record status:", error);
      alert.error("Failed to update signature record status.");
    }
  };


  useEffect(()=>{
    console.log(updateSignatureRecord)
    if(!updateSignatureRecordLoading && updateSignatureRecord?.success)
    {
      successMessage()
      dispatch(getAllSignatureRecord());
    }
  }, [updateSignatureRecord, updateSignatureRecordLoading])
  
  const successMessage = () => {

  }
  const filteredRecords = allSignatureRecord?.filter(record =>
    record.name.toLowerCase().includes(searchName.toLowerCase()) &&
    record.designation.toLowerCase().includes(searchDesignation.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddSignatureClick = () => {
    navigate('/addsignaturerecord');
  };

  const indexOfLastRecord = page * rowsPerPage + rowsPerPage;
  const indexOfFirstRecord = page * rowsPerPage;
  const currentRecords = filteredRecords?.slice(indexOfFirstRecord, indexOfLastRecord);

  // Define columns for ReactTable
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: 'S:No',
  //       accessor: 'srNo',
  //     },
  //     {
  //       Header: 'Name',
  //       accessor: 'name',
  //     },
  //     {
  //       Header: 'Designation',
  //       accessor: 'designation',
  //     },
  //     {
  //       Header: 'Status',
  //       accessor: 'status',
  //       Cell: ({ row }) => (
  //         <Switch
  //         onChange={() => toggleStatus(row.original._id, row.original.status,allSignatureRecord)}
  //         checked={row.original.status}
  //         onColor="#007bff"
  //         offColor="#ff7b7b"
  //           border='none'
  //           uncheckedIcon={false}
  //           checkedIcon={false}
  //           />
  //       ),
  //     },
  //   ],
  //   []
  // );

  const columns = [
    { field: "name", label: "Name" },
    { field: "designation", label: "Designation" },
  
  ];
  
  const actions = [
    {
      label: "Signature Toggle",
      color: "yellow",
      handler: (itemId) => toggleStatus(itemId._id, itemId.status, allSignatureRecord),
    },
  ];      


  // Format data for ReactTable
  const tableData = React.useMemo(
    () =>
      currentRecords?.map((record, index) => ({
        srNo: indexOfFirstRecord + index + 1,
        name: record.name,
        designation: record.designation,
        status: record.status,
        _id: record._id,
      })),
    [currentRecords, indexOfFirstRecord]
  );

  const { roleTask } = useSelector(
    (state) => state.userData
  );
  var task = false;
  task = roleTask.find((e) => e?.task_id?.name === "Add Signature Record" && e.status === true);


  return (
    <Fragment>
       <div className={`Signature`}>
                <div className="secondContainer">
                    <div className="contentt-box">
                        <div className="heading-container">
                            <h3>{"Issueing Authority"}</h3>
                            <h5>
                                <span className="total-records">
                                    {"Total Records"}&nbsp;&nbsp;
                                    <EventAvailableIcon fontSize="small" />
                                </span>
                                {/* <span className="rowCount">{rowCount}</span> */}
                            </h5>
                        </div>
                        <div className="excelDiv">
                            {task ? <button onClick={handleAddSignatureClick}>Add Signature</button>: null}
                        </div>
                    </div>
                    <div className="search-box">
                      <input
                        type="text"
                        placeholder="Search by Name"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Search by Designation"
                        value={searchDesignation}
                        onChange={(e) => setSearchDesignation(e.target.value)}
                      />
                    </div>
                    <div className="table-container">
                                {loading ? 
                                    (
                                        <Loader />
                                    ) : 
                                    (
                                        <TableComponentId
                                          data={tableData}
                                          columns={columns}
                                        actions={actions}
                                        signatureToggle={signatureToggle}
                                        />
                                    )
                                }
                            
                    </div>
                </div>
            </div>
      {/* <div className="main-page-container">
        <div className="pageName_And_Button">
          <h2 className="add-visa-type-title">Signature Record</h2>

          {task ? <button className="button-yellow" onClick={handleAddSignatureClick}>Add Signature</button>: null}
          
        </div>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search by Name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Search by Designation"
            value={searchDesignation}
            onChange={(e) => setSearchDesignation(e.target.value)}
          />
        </div>
        <div className="table-container">
          {loading ? (
            <Loader />
          ) : (
            <ReactTable data={tableData} columns={columns} />
          )}
        </div>
        <TablePagination
          component="div"
          count={filteredRecords.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div> */}
    </Fragment>
  );
};

export default ViewSignatureRecord;
