import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import AssignModal from './AssignModal';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../Loader/Loader';
import { useAlert } from 'react-alert';
// import './ViewRequestModal.css';
// import '../Modal.css';
import CommentModal from './CommentModal';
import { getAllRequest, updateRequestedProductStatus, clearError, getRequestById, getProductQuantity } from '../../../actions/requestAction';
import LoaderModal from '../LoaderModal/LoaderModal';
import TableComponentId from '../../Table Component/tableComponent';
// import "./model.css"
const ViewRequestModal = ({ isModalOpen, setIsModalOpen, currentRequestId }) => {
    const { loading, requestData, error } = useSelector((state) => state.requests);
    const alert = useAlert();
    const dispatch = useDispatch();
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
    const [requestItems, setRequestItems] = useState([]);
    const [currentProductId, setCurrentProductId] = useState('');
    const [event, setEvent] = useState();
    const linkk = "handleReject";
    const actionUpdate = "handleAssign";
    const action3 = "handleEdit";
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleAssign = (requestedItem) => {
        console.log('assinged')
        console.log(requestedItem)
        setRequestItems(requestedItem);
        setIsAssignModalOpen(true);
        dispatch(getProductQuantity(requestedItem?._id?._id))
    };

    const handleReject = (product_id) => {
        setIsCommentModalOpen(true);
        setCurrentProductId(product_id);
        
    };

    const handleEdit = (requestedItem) => {
        console.log('edit')
        console.log(requestItems)
        setEvent('Edit');
        setRequestItems(requestedItem);
        dispatch(getProductQuantity(requestedItem?._id?._id))
        setIsAssignModalOpen(true);
    };

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }
    }, [error, alert, dispatch]);

    const columns = [
        { field: "_id.name", label: "Product Name" },
        { field: "requested_quantity", label: "Requested Quantity" },
        { field: "received_quantity", label: "Received Quantity" },
        { field: "status", label: "Status" },
        { field: "comment", label: "Comment" },
      ];

      
    const actions = [
        {
          label: "handleReject",
          color: "yellow",
          handler: (itemId) => handleReject(itemId),
        },
        {
          label: "handleAssign",
          color: "green",
          handler: (itemId) => handleAssign(itemId),
        },
        {
            label: "handleEdit",
            color: "green",
            handler: (itemId) => handleEdit(itemId),
          },
      ];      

    return (
        <Fragment>
            {loading ? (
                <div className="modal">
                <div className="modal-content">
                    <Loader />
                    </div></div>
            ) : (<>
                            <div className="modal">
                            <div className="modal-content">
                                <div className='buttonDiv'>
                                    <button onClick={toggleModal}><CloseIcon /></button>
                                </div>
                                <div className="model-header-container">
                                    <></>
                                    <div className='header-Row'>
                                        <h5>Request Number:&nbsp;</h5>
                                        <h6>{requestData?.request_number}</h6>
                                    </div>
                                    <div className='header-Row'>
                                        <h5>Requested By:&nbsp;</h5>
                                        <h6>{requestData?.user_id?.name}</h6>
                                    </div>
                                    <div className='header-Row'>
                                        <h5>Request Status:&nbsp;</h5>
                                        <h6>{requestData?.status}</h6>
                                    </div>
                                </div>
                            <div className="View_Modal_Table_div">
                                <div className="View_Modal_Table_wrapper">
                                    <></>
                                    <TableComponentId
                                        data={requestData?.products}
                                        columns={columns}
                                        actions={actions}
                                        handleReject={linkk}
                                        handleAssign={actionUpdate}
                                        handleEdit={action3}
                                    />
                                 
                                </div>
                            </div>
                            </div>
                            </div>
            </>)}
            {isAssignModalOpen && 
            <AssignModal
                event={event}
                isAssignModalOpen={isAssignModalOpen}
                setIsAssignModalOpen={setIsAssignModalOpen}
                requestItems={requestItems}
                currentRequestId={currentRequestId}
            />}
            {isCommentModalOpen && <CommentModal
                isCommentModalOpen={isCommentModalOpen}
                setIsCommentModalOpen={setIsCommentModalOpen}
                currentProductId={currentProductId}
                currentRequestId={currentRequestId}
            />}
        </Fragment>
    );
};

export default ViewRequestModal;
