import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompany, getCompanyById } from '../../actions/companyAction';
import { useNavigate } from 'react-router-dom';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import TablePagination from '@mui/material/TablePagination';
import ReactTable from '../ReactTable'; // Adjust the path as needed
import Loader from '../Loader/Loader';
import TableComponentId from '../Table Component/tableComponent';
import UpdateCompany from './updateCompany';

const AllCompany = () => {
  const { loading, allCompany, error } = useSelector((state) => state.company);
  const update = "Update"
  const [searchName, setSearchName] = useState('');
  const [searchDescription, setSearchDescription] = useState('');
  const [companyUpdateModel, setCompanyUpdateModel] = useState(false)
  const [companyId, setCompanyId] = useState()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllCompany());
  }, [dispatch]);

  const filteredCompanies = allCompany?.filter(company =>
    company.name.toLowerCase().includes(searchName.toLowerCase()) &&
    company.description.toLowerCase().includes(searchDescription.toLowerCase())
  ) || [];

  const handleAddCompany = () => {
    navigate('/addcompany');
  };

  const updateCompanyRecord = (data) => {
    // setPage(newPage);
    setCompanyId(data?._id)
    setCompanyUpdateModel(true)
    dispatch(getCompanyById(data?._id));
  };



  // Define columns for ReactTable
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: 'SrNo',
  //       accessor: 'srNo',
  //     },
  //     {
  //       Header: 'Name',
  //       accessor: 'name',
  //     },
  //     {
  //       Header: 'Description',
  //       accessor: 'description',
  //     },
  //   ],
  //   []
  // );

  const columns = [
    { field: "name", label: "Name" },
    { field: "description", label: "Description" }
  ];

  const actions =[
    {
      label: "Update",
      color: "green",
      handler: (item)=> updateCompanyRecord(item)
    }
  ]
  // Format data for ReactTable
  // const tableData = React.useMemo(
  //   () =>
  //     currentCompanies.map((company, index) => ({
  //       srNo: indexOfFirstCompany + index + 1,
  //       name: company.name,
  //       description: company.description,
  //       id: company.id,
  //     })),
  //   [currentCompanies, indexOfFirstCompany]
  // );

  const { roleTask } = useSelector(
    (state) => state.userData
  );
  var task = false;
  task = roleTask.find((e) => e?.task_id?.name === "Add Product Company" && e.status === true);


  return (
    <div className={`Company`}>
    <div className="secondContainer">
        <div className="contentt-box">
            <div className="heading-container">
                <h3>{"Company"}</h3>
                <h5>
                    <span className="total-records">
                        {"Total Records"}&nbsp;&nbsp;
                        <EventAvailableIcon fontSize="small" />
                    </span>
                    {/* <span className="rowCount">{rowCount}</span> */}
                </h5>
            </div>
            <div className="excelDiv">
                {task ? <button onClick={handleAddCompany}>Add Company</button>: null}
            </div>
        </div>
        <div className="search-box">
          <input
            type="text"
            className="company-search-input"
            placeholder="Search by name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <input
            type="text"
            className="company-search-input"
            placeholder="Search by description"
            value={searchDescription}
            onChange={(e) => setSearchDescription(e.target.value)}
            />
          
        </div>
        <div className="table-container">
                    {loading ? 
                        (
                            <Loader />
                        ) : 
                        (
                            <TableComponentId
                              data={filteredCompanies}
                              columns={columns}
                            actions={actions}
                            update={update}
                            />
                        )
                    }
                
        </div>
        {
          companyUpdateModel && (
            <UpdateCompany companyId = {companyId} companyUpdateModel={companyUpdateModel} setCompanyUpdateModel={setCompanyUpdateModel} />
          )
        }
       
    </div>
</div>
    // <div className="main-page-container">
    //   <div className='pageName_And_Button'>
    //     <h1>All Companies</h1>
    //     {task ? 
        
    //     <button className="button-yellow" onClick={handleAddCompany}>Add Company</button>
    //     : null}
    //   </div>
    //   <div className="search-bar">
    //     <input
    //       type="text"
    //       className="company-search-input"
    //       placeholder="Search by name"
    //       value={searchName}
    //       onChange={(e) => setSearchName(e.target.value)}
    //     />
    //     <input
    //       type="text"
    //       className="company-search-input"
    //       placeholder="Search by description"
    //       value={searchDescription}
    //       onChange={(e) => setSearchDescription(e.target.value)}
    //     />
    //   </div>
    //   <div className='table-container'>

    //     {loading ?  (<Loader/>):(
    //       <ReactTable data={tableData} columns={columns} />
    //     )}
    //   </div>
    //   <TablePagination
    //     component="div"
    //     count={filteredCompanies.length}
    //     page={page}
    //     onPageChange={handleChangePage}
    //     rowsPerPage={rowsPerPage}
    //     onRowsPerPageChange={handleChangeRowsPerPage}
    //   />
    // </div>
  );
};

export default AllCompany;
