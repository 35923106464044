import React, { Fragment, useEffect, useState, useMemo , useContext} from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  useLocation } from 'react-router-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Loader from '../../Loader/Loader';
import { State } from '../DemandContext/context';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_DEMAND_DATA } from '../../../Redux/constants/demandConstants';
import { useNavigate } from 'react-router-dom';
import { getAllProduct, clearError } from '../../../actions/demandAction';
import { useAlert } from 'react-alert';
import TablePagination from '@mui/material/TablePagination';
import { IoBagAddOutline } from "react-icons/io5";
import ReactTable from '../../ReactTable'; 
import Tippy from '@tippyjs/react';
import CloseIcon from '@mui/icons-material/Close';
import TableComponentId from '../../Table Component/tableComponent';

function AllProductTable() {
    const alert = useAlert();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {    
        applicationId, 
        setApplicationId,
        date, 
        setDate,
        subject, 
        setSubject,
        description, 
        setDescription,
        locationId, productId, setProductId,
        name, setName,specifications, setSpecifications,
        productDescription, setProductDescription,
        type, setType,company, setCompany, setQuantity, quantity,
        setLocationId, handleSubmit} = useContext(State)
    const viewDemandDetail = "View Demand Details"
    const { loading, allProduct, error } = useSelector((state) => state.product);
    const [currentProduct, setCurrentProduct] = useState({});
    const [inputQuantityChange, setInputQuantityChange] = useState('');
    const [isQuantityModalOpen, setIsQuantityModalOpen] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleAddButton = (product) => {
        console.log(product)
        setProductId(product?._id)
        setName(product?.name)
        setSpecifications(product?.specifications)
        setProductDescription(product?.description)
        setType(product?.type_id?.name)
        setCompany(product?.company_id?.name)
        setCurrentProduct({
            _id: product?._id,
            name: product?.name,
            specifications: product?.specifications,
            description: product?.description,
            type: product?.type_id?.name,
            company: product?.company_id?.name,
        });
        setIsQuantityModalOpen(true);
    };

    const handleSearch = (e) => {
        setSearchInput(e.target.value);
    };

    const handleQuantityInputChange = (e) => {
        setQuantity(e.target.value)
        setInputQuantityChange(e.target.value);
    };

    const handleAddQuantityButton = () => {
        handleSubmit()
        if (inputQuantityChange === '') {
            return alert.error('Add Input first');
        }
        let updatedProduct = { ...currentProduct, quantity: inputQuantityChange };
        dispatch({ type: ADD_DEMAND_DATA, payload: updatedProduct });
        setIsQuantityModalOpen(false);
        navigate("/demand", {state: "/demandproduct"});
    };

    useEffect(() => {
        dispatch(getAllProduct());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }
    }, [error, alert, dispatch]);

    const searchData = useMemo(() => {
        return allProduct?.filter((product) => {
            return product?.name?.toLowerCase().includes(searchInput?.toLowerCase());
        });
    }, [searchInput, allProduct]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const indexOfLastProduct = page * rowsPerPage + rowsPerPage;
    const indexOfFirstProduct = page * rowsPerPage;
    const currentProducts = searchData.slice(indexOfFirstProduct, indexOfLastProduct);


    const columns = [
        { field: "name", label: "Name" },
        { field: "specifications", label: "Specifications"},
        { field: "description", label: "Description"},
      ];

    const actions = [
        {
          label: "View Demand Details",
          color: "yellow",
          handler: (itemId) => handleAddButton(itemId),
        },
    ];      

    // const columns = useMemo(() => [
    //     {
    //         Header: 'S:No',
    //         accessor: (row, index) => indexOfFirstProduct + index + 1,
    //     },
    //     {
    //         Header: 'Name',
    //         accessor: 'name',
    //     },
    //     {
    //         Header: 'Specifications',
    //         accessor: 'specifications',
    //     },
    //     {
    //         Header: 'Description',
    //         accessor: 'description',
    //     },
    //     {
    //         Header: 'Action',
    //         Cell: ({ row }) => (
    //             <Tippy content='Add item'>
    //             <button
    //                 className='action-btn'
    //                 onClick={() => handleAddButton(row.original)}
    //             >
    //                 <IoBagAddOutline />
    //             </button>
    //             </Tippy>
    //         ),
    //     },
    // ], [indexOfFirstProduct]);

    return (
        <Fragment>
             <div className={`Demand`}>
                <div className="secondContainer">
                    <div className="contentt-box">
                        <div className="heading-container">
                            <h3>{"All Products"}</h3>
                            <h5>
                                <span className="total-records">
                                    {"Total Records"}&nbsp;&nbsp;
                                    <EventAvailableIcon fontSize="small" />
                                </span>
                                {/* <span className="rowCount">{rowCount}</span> */}
                            </h5>
                        </div>
                        <div className="excelDiv">
                            {
                            <button onClick={()=> navigate(`${location.state}`, {state: "/demandproduct"})}>Back</button>}
                        </div>
                    </div>
                    <div className="search-box">
                        <input 
                            type="text" 
                            placeholder="Search Product Name" 
                            value={searchInput} 
                            onChange={handleSearch} 
                        />
                    </div>
                    <div className="table-container">
                    <TableComponentId data={currentProducts}
                                        columns={columns}
                                        actions={actions}
                                        viewDemandDetail={viewDemandDetail}/>
                        {/* <ReactTable data={currentProducts} columns={columns} /> */}
                    </div>
                
                </div>
             </div>

            {isQuantityModalOpen && (<>
             <div className='modal'>
                        <div className='modal-content'>
                            
                                <div className='buttonDiv'>
                                    <button onClick={() => setIsQuantityModalOpen(!isQuantityModalOpen)}><CloseIcon /></button>
                                </div>
                    <div className='form'>
                            <div className="formRow">
                                <div className="inputSection">
                                    <label>{"Product Name"}</label>
                                    <input
                                            type='text'
                                            value={name}
                                            disabled
                                        />
                                    </div>
                                    <div className="inputSection">
                                    <label>{"Quantity"}</label>
                                    <input
                                                type='number'
                                                onChange={handleQuantityInputChange}
                                            />
                                </div>
                                </div>
                            <div className='buttonRow'>
                            <button className='button button-add-product' onClick={handleAddQuantityButton}>
                                                Add
                                            </button>
                            </div>
                       
                        </div>
                </div>
            </div>
            </>)}
            {/* {loading ? (
                <div style={{width:'100%', height:'100vh'}}>
                    <Loader />
                </div>
            ) : (
                <div className='main-page-container'>
                    <div className='pageName_And_Button'>
                        <h3>All Products</h3>
                    </div>
                    <div className="search-bar">
                        <input 
                            type="text" 
                            placeholder="Search Product Name" 
                            value={searchInput} 
                            onChange={handleSearch} 
                        />
                    </div>
                    <div className='table-container'>
                        <ReactTable data={currentProducts} columns={columns} />
                    </div>
                    <TablePagination
                        component="div"
                        count={searchData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <Modal
                        className='Modal'
                        size='lg'
                        isOpen={isQuantityModalOpen}
                        toggle={() => setIsQuantityModalOpen(!isQuantityModalOpen)}
                    >
                        <ModalHeader >
                            <FontAwesomeIcon
                                className='svg-icon'
                                icon={faTimes}
                                style={{ float: 'right', cursor: 'pointer' }}
                                onClick={() => setIsQuantityModalOpen(false)}
                            />
                        </ModalHeader>
                        <ModalBody>
                            <table className='modal-body-table'>
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <td>{currentProduct?.name}</td>
                                        <td>
                                            <input
                                                type='number'
                                                onChange={handleQuantityInputChange}
                                            >
                                            </input>
                                        </td>
                                        <td>
                                            <button className='submit_button' onClick={handleAddQuantityButton}>
                                                Add
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                    </Modal>
                </div>
            )} */}
        </Fragment>
    );
}

export default AllProductTable;
