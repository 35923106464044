import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { getAllCompany, getCompanyById, updateCompanyRecord } from '../../actions/companyAction';
let typeAssigned= []
const UpdateCompany = ({ companyId, companyUpdateModel, setCompanyUpdateModel}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const alert = useAlert()
    const [companyName, setCompanyName] = useState()
    const [companyDescription, setCompanyDescription] = useState()
    const [data, setData] = useState()
    const assignProductTypeRole = "ProductTypeAssignedRole"
    const { companyById, companyByIdLoading } = useSelector((state) => state.companyById);
    const {updateCompany, updateCompanyLoading} = useSelector((state)=> state.updateCompany)

    useEffect(()=>{
        if(updateCompany?.success && !updateCompanyLoading)
        {
            successMessage(updateCompany?.message)
        }
    }, [updateCompany, updateCompanyLoading])

    const successMessage=(message) =>{
        dispatch(getAllCompany())
        setCompanyUpdateModel(false)
        alert.success(message)
    }

    useEffect(()=>{
        console.log(companyById)
        if(!companyByIdLoading)
        {
            setCompanyName(companyById?.record?.name)
            setCompanyDescription(companyById?.record?.description)
        }
    }, [companyById, companyByIdLoading])

    const handleUpdate = (data) => {
        dispatch(updateCompanyRecord(companyId, companyName, companyDescription))
    };

  
    const clickCloseIcon = () => {
    
        setCompanyUpdateModel(!companyUpdateModel);
    };


    return (
        <Fragment>
              
                            <div className="modal">
                            <div className="modal-content">
                                <div className='buttonDiv'>
                                    <button onClick={clickCloseIcon}><CloseIcon /></button>
                                </div>
                                <div className='search-box'></div>
                             <div className='form'>
                                    <div className='formRow'>
                                        <div className='inputSection'>
                                            <label>Company Name</label>
                                            <input
                                                type='text'
                                                onChange={(e)=> setCompanyName(e.target.value)}
                                                value={companyName}
                                            />  
                                        </div><div className='inputSection'>
                                            <label>Company Description</label>
                                            <input
                                                type='text'
                                                onChange={(e)=> setCompanyDescription(e.target.value)}
                                                value={companyDescription}
                                            />  
                                        </div>
                                    </div>
                                    <div className='buttonRow'>
                                        <button className='button button-back' onClick={handleUpdate}>
                                            Update
                                        </button>
                                    </div>

                                </div>
                           
                            </div>
                            </div>
            
           
  
        </Fragment>
    );
};

export default UpdateCompany;
