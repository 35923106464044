import {
    GET_ALL_PRODUCT_TYPE_REQUEST,
    GET_ALL_PRODUCT_TYPE_SUCCESS,
    GET_ALL_PRODUCT_TYPE_FAIL,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
    ADD_PRODUCT_TYPE_REQUEST,
    ADD_PRODUCT_TYPE_SUCCESS,
    ADD_PRODUCT_TYPE_FAIL,
    GET_ALL_PRODUCT_REQUEST,
    GET_ALL_PRODUCT_SUCCESS,
    GET_ALL_PRODUCT_FAIL,
    GET_ALL_PRODUCT_FROM_LOCATION_REQUEST,
    GET_ALL_PRODUCT_FROM_LOCATION_SUCCESS,
    GET_ALL_PRODUCT_FROM_LOCATION_FAIL,
    CLEAR_MESSAGE,
    CLEAR_ERROR,
    GET_PRODUCTTYPE_BYID_REQUEST,
    GET_PRODUCTTYPE_BYID_SUCCESS,
    GET_PRODUCTTYPE_BYID_FAIL,
    UPDATE_PRODUCTTYPESTATUS_BYID_REQUEST,
    UPDATE_PRODUCTTYPESTATUS_BYID_SUCCESS,
    UPDATE_PRODUCTTYPESTATUS_BYID_FAIL
} from '../constants/productConstants'

export const productReducer = (state = { allProductType: [],message:'',allProduct:[] }, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT_TYPE_REQUEST:
    case ADD_PRODUCT_REQUEST:
    case ADD_PRODUCT_TYPE_REQUEST:
    case GET_ALL_PRODUCT_FROM_LOCATION_REQUEST:
    case GET_ALL_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        allProductType: action.payload,
      };
    case GET_ALL_PRODUCT_FROM_LOCATION_SUCCESS:
    case GET_ALL_PRODUCT_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        allProduct:action.payload,
      };
    case ADD_PRODUCT_SUCCESS:
      console.log(action.payload.message);
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        allProduct:action.payload.allProduct
      };
    case ADD_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case GET_ALL_PRODUCT_TYPE_FAIL:
    case ADD_PRODUCT_FAIL:
    case ADD_PRODUCT_TYPE_FAIL:
    case GET_ALL_PRODUCT_FROM_LOCATION_FAIL:
    case GET_ALL_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message:null,
      };
    default:
      return state;
  }
};


export const productTypeByIdReducer = (state = { producTypeById: []}, action) => {
  switch (action.type) {
    case GET_PRODUCTTYPE_BYID_REQUEST:
   
      return {
        ...state,
        producTypeByIdLoading: true,
      };
    case GET_PRODUCTTYPE_BYID_SUCCESS:
      return {
        ...state,
        producTypeByIdLoading: false,
        producTypeById: action.payload,
      };
    case GET_PRODUCTTYPE_BYID_FAIL:
      return {
        ...state,
        producTypeByIdLoading: false,
        producTypeByIdError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        producTypeByIdError: null,
      };
    default:
      return state;
  }
};


export const updateProductTypeStatusReducer = (state = { producTypeStatus: []}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTTYPESTATUS_BYID_REQUEST:
   
      return {
        ...state,
        producTypeStatusLoading: true,
      };
    case UPDATE_PRODUCTTYPESTATUS_BYID_SUCCESS:
      return {
        ...state,
        producTypeStatusLoading: false,
        producTypeStatus: action.payload,
      };
    case UPDATE_PRODUCTTYPESTATUS_BYID_FAIL:
      return {
        ...state,
        producTypeStatusLoading: false,
        producTypeStatusError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        producTypeStatusError: null,
      };
    default:
      return state;
  }
};
