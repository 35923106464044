import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../Loader/Loader';
import { useAlert } from 'react-alert';
import { getAllRequest, clearError, getRequestById } from '../../actions/requestAction';

import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ViewRequestModal from '../Modal/RequestModel/ViewRequestModal';
import TablePagination from '@mui/material/TablePagination';
import { IoEyeSharp } from "react-icons/io5";
import ReactTable from '../ReactTable';
import Tippy from '@tippyjs/react';
import './Request.css'
import TableComponentId from '../Table Component/tableComponent';

const Request = () => {
    const [event, setEvent] = useState('');
    const dispatch = useDispatch();
    const alert = useAlert();
    const viewDetail = "View Details"
    const { requests, loading, error } = useSelector((state) => state.requests);

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }
        dispatch(getAllRequest());
    }, [dispatch, alert, error]);

    const [searchReqId, setSearchReqId] = useState('');
    const [searchUser, setSearchUser] = useState('');
    const [searchDateTime, setSearchDateTime] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const filteredRequests = requests?.filter(request =>
        request.request_number.toLowerCase().includes(searchReqId.toLowerCase()) &&
        request.user_id.name.toLowerCase().includes(searchUser.toLowerCase()) &&
        new Date(request.createdAt).toLocaleString().toLowerCase().includes(searchDateTime.toLowerCase()) &&
        request.status.toLowerCase().includes(searchStatus.toLowerCase())
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const indexOfLastRequest = page * rowsPerPage + rowsPerPage;
    const indexOfFirstRequest = page * rowsPerPage;
    const currentRequests = filteredRequests.slice(indexOfFirstRequest, indexOfLastRequest);

    const [currentRequestId, setCurrentRequestId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);

    const handleViewButtonClick = (request) => {
        setIsModalOpen(true);
        console.log(request)
        const currentRequestId = request?._id;
        dispatch(getRequestById(request._id));
        setCurrentRequestId(currentRequestId);
        // dispatch(getRequestedProduct(currentRequestId));
        setSelectedRequest(request);
    };

    const columns = [
        { field: "request_number", label: "Request Id" },
        { field: "user_id.name", label: "User"},
        { field: "status", label: "Status" },
        { field: "createdAt", label: "Date", format: "date" },
        { field: "createdAt", label: "Time", format: "time" },
      ];


       
      const actions = [
        {
          label: "View Details",
          color: "yellow",
          handler: (itemId) => handleViewButtonClick(itemId),
        },
      ];      

    return (
        <Fragment>
            <div className={`Request`}>
                <div className="secondContainer">
                    <div className="contentt-box">
                        <div className="heading-container">
                            <h3>{"User Request"}</h3>
                            <h5>
                                <span className="total-records">
                                    {"Total Records"}&nbsp;&nbsp;
                                    <EventAvailableIcon fontSize="small" />
                                </span>
                                {/* <span className="rowCount">{rowCount}</span> */}
                            </h5>
                        </div>
                    </div>
                    <div className="search-box">
                    <input
                        type="text"
                        placeholder="Enter Req.Id"
                        value={searchReqId}
                        onChange={(e) => setSearchReqId(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Enter User Name"
                        value={searchUser}
                        onChange={(e) => setSearchUser(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Enter Date/Time"
                        value={searchDateTime}
                        onChange={(e) => setSearchDateTime(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Enter Status"
                        value={searchStatus}
                        onChange={(e) => setSearchStatus(e.target.value)}
                    />
                    </div>
                    <div className="table-container">
                        {currentRequests.length > 0 ? (
                            <>
                                {loading ? 
                                    (
                                        <Loader />
                                    ) : 
                                    (
                                        <TableComponentId data={filteredRequests}
                                        columns={columns}
                                        actions={actions}
                                        viewDetail={viewDetail}/>
                                    )
                                }
                            </>
                            ) : (
                            <div className="no-data-found">No Data Found</div>
                        )}
                    </div>
                </div>
            </div>
            {isModalOpen && selectedRequest && (
                <ViewRequestModal
                    event={event}
                    request={selectedRequest}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    currentRequestId={currentRequestId}
                />
            )}
            {/* <div className="main-page-container">
                <div className='pageName_And_Button'>
                    <h3>User Requests</h3>
                </div>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Enter Req.Id"
                        value={searchReqId}
                        onChange={(e) => setSearchReqId(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Enter User Name"
                        value={searchUser}
                        onChange={(e) => setSearchUser(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Enter Date/Time"
                        value={searchDateTime}
                        onChange={(e) => setSearchDateTime(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Enter Status"
                        value={searchStatus}
                        onChange={(e) => setSearchStatus(e.target.value)}
                    />
                </div>
                {currentRequests.length > 0 ? (
                    <>
                        {loading ? (
                            <Loader />
                        ) : (<TableComponentId data={currentRequests}
                            columns={columns}
                            actions={actions}
                            viewDetail={viewDetail}/>)
                        }
                </>
                ) : (

                    <div className="no-data-found">No Data Found</div>
                )}

                
            </div>
            {isModalOpen && selectedRequest && (
                <ViewRequestModal
                    event={event}
                    request={selectedRequest}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    currentRequestId={currentRequestId}
                />
            )} */}
        </Fragment>
    );
};

export default Request;
