import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import DemandContext from "./component/demand/DemandContext/context"
import "./SaasStyling/app.scss"
import store from './store';
import { positions, transitions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import PreventBackButton from './PreventBackbutton';
const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
  transition: transitions.SCALE,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <PreventBackButton /> */}
  <AlertProvider template={AlertTemplate} {...options}>
    <Provider store={store}>
      {/* <div className="alert-container" style={{ zIndex: 1591 }}> */}
        <DemandContext>
          <App />
        </DemandContext>
      {/* </div> */}
    </Provider>
  </AlertProvider>
  </React.StrictMode>
);
