import React, { useEffect, useRef, useState, useContext } from 'react';
import { useReactToPrint } from "react-to-print";
import ReactToPrint from "react-to-print";
import swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import TemplatePrint from '../printTemplate/TemplatePrint.js';
import PrintTemplate from "../viewDemand/printTemplate.js"
import printJs from "print-js"
import { State } from '../DemandContext/context.js';
import { MdOutlineDelete } from "react-icons/md";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { clearError, saveDemand, getActiveAssistantDirectorSignatureRecord } from '../../../actions/demandAction.js';
import { useAlert } from 'react-alert';
import TablePagination from '@mui/material/TablePagination';
import { CLEAR_DETAILS, CLEAR_DEMAND_DATA, UPDATE_DATA } from '../../../Redux/constants/demandConstants.js';
import ReactTable from '../../ReactTable'; // Ensure the path is correct
import Tippy from '@tippyjs/react';
import './../AllProduct.css'; // Make sure you have this or update the path
import TableComponentId from '../../Table Component/tableComponent.js';
import { getActiveSignatureRecord } from '../../../actions/signatureRecordAction.js';

const AddedProduct=({allFieldsFilled, onCallFunction }) =>{
    const dispatch = useDispatch();
    const alert = useAlert();
    const user_id = '65f9808effdbe72cf5b6fdcb';
    const { error, loading, data, detail, AssistantDirector } = useSelector(state => state.demandReducer);
    const [buttonClicked, setButtonClicked] = useState(false)
    const {    
        applicationId, 
        setApplicationId,
        date, 
        setDate,
        subject, 
        setSubject,
        description, 
        setDescription,
        locationId, demandList,setDemandList,
        setLocationId, handleSubmit} = useContext(State)
    const {postDemand, postDemandLoading} = useSelector((state)=> state.postDemand)
    const {activeSignatureRecord, activeSignatureRecordLoading}=useSelector((state)=> state.activeSignatureRecord)
    const componentPDF = useRef();
    // const signatureRecord_id = AssistantDirector[0]?._id;
    // const signatureRecord_id;
    const deleteDemandItem = "DeleteDemandItemList"
    const buttonRef = useRef(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleDemandSave = () => {
        console.log(data)
        const demandData = {
            products: demandList,
            subject: subject,
            applicationId: applicationId,
            date: date,
            description: description,
            locationId: locationId,
            user_id: user_id,
            signatureRecord_id: activeSignatureRecord[0]._id
        };
        dispatch(saveDemand(demandData));

        // clearData();
    };

    const clearData = () => {
        dispatch({ type: CLEAR_DEMAND_DATA });
        dispatch({ type: CLEAR_DETAILS });
    };

    const handlePrint = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Demand"
    });

    const handleDeleteProduct = (productId) => {
        console.log(productId)
        console.log(demandList)
        
        // const handleDelete = (id) => {
            swal
              .fire({
                icon: "warning",
                title: "Warning",
                text: "Are you sure you want to delete",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "Cancel",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  setButtonClicked(false)
                  const updatedItems = [...demandList];
                  updatedItems.splice(productId, 1);
                  setDemandList(updatedItems);
                  const updatedData = data.filter((item) => item?._id !== productId);
        dispatch({ type: UPDATE_DATA, payload: updatedData });
                }
              });
        //   };
        // const updatedData = data.filter((item) => item?._id !== id);
        // dispatch({ type: UPDATE_DATA, payload: updatedData });
    };

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }
        dispatch(getActiveAssistantDirectorSignatureRecord());
        dispatch(getActiveSignatureRecord())
    }, [error, dispatch, alert]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const indexOfLastProduct = page * rowsPerPage + rowsPerPage;
    const indexOfFirstProduct = page * rowsPerPage;
    const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);

    const handlePrintDownload = async()=>{
        const result = await SubmitData()
    }

    const SubmitData = async() =>{
        console.log('alefji')
        setButtonClicked(true)
        const demandData = {
            products: demandList,
            subject: subject,
            description: description,
            applicationId: applicationId,
            date: date,
            locationId: locationId,
            user_id: user_id,
            signatureRecord_id: activeSignatureRecord[0]?._id,
            authorizedPerson: activeSignatureRecord[0]?.name
        };
        const response = await saveDemand(demandData)
        console.log(response)
        if(!response.success)
        {
            swal
        .fire({
          icon: "warning",
          title: "Error",
          text: `${response?.error?.message}`,
          customClass: {
            popup: "custom-swal-popup",
          },
        })
        componentPDF?.current.cancel()
        }
        // dispatch(saveDemand(demandData));
    }

    const columns = [
        { field: "name", label: "Name" },
        { field: "specifications", label: "Specifications" },
        // { field: "description", label: "Description"},
        { field: "quantity", label: "Quantity" },
      ];

      const actions = [
        {
          label: "DeleteDemandItemList",
          color: "yellow",
          handler: (itemId) => handleDeleteProduct(itemId),
        },
      ];      


    return (
        <div className='demandInvoiceContainer' style={{ paddingTop: '0px' }}>
            <div ref={componentPDF} className='print-div' 
            style={{display:'none', padding: "20px"}}
            >
                {
                  <PrintTemplate applicationId={applicationId} date={date} subject={subject} description={description} demandList={demandList}/>
                    //  ( <TemplatePrint data={data} detail={detail} />)
                }
               
            </div>
            <div className='buttonRow'>
            <button
                  className={`button button-back ${
                  allFieldsFilled ? "clicked" : "Notclicked"
                  }`}
                  disabled={!allFieldsFilled}
                  onClick={onCallFunction}
                  >
                  Add Item
                </button>
                {
                    demandList.length > 0 && 
                    (  
                        <ReactToPrint
                        trigger={() =>
                         demandList?.length > 0 ? 
                          (
                            <button
                            //   ref={componentPDF}
                            // ref={buttonRef}
                              disabled={buttonClicked}
                              className={`button button-add-product`} 
                            >
                              Generate Invoice &nbsp;&nbsp;<SaveAltIcon />
                            </button>
                          ) : ( <h1></h1>)
                          }
                        content={() => componentPDF.current}
                        onBeforeGetContent={SubmitData}
                        onAfterPrint={() => {
                          setButtonClicked(false)
                          setDemandList([])
                          setApplicationId('')
                          setDate('')
                          setLocationId('')
                          setSubject('')
                          setDescription('')
                          }}
                      />
                    //      <button
                    //     className={`button button-add-product
                    //     `}
                    //     disabled={!allFieldsFilled}
                    //     onClick={SubmitData}
                    //     // onClick={handleDemandSave()}
                    //     >
                    //     Generate Invoice
                    //   </button>
                      )
                }
             
                </div>
            {/* { demandList.length > 0 ? ( */}
                <>
                    {/* <div className='contentt-box'>
                        <div className='heading-container'>
                            <h3>Added Products</h3>
                        </div>
                    </div> */}
                    <div className='table-container'>
                        <TableComponentId 
                            data={demandList}
                            columns={columns}
                            actions={actions}
                            deleteDemandItem={deleteDemandItem}/>
                        {/* <ReactTable data={currentProducts} columns={columns} /> */}
                    </div>
                    {/* <button  className="button button-back"onClick={() => { handlePrint(); handleDemandSave(); }}>Generate Invoice</button> */}
                    
                </>
            {/* ) : (<></>
            )} */}
        </div>
    );
}

export default AddedProduct;
