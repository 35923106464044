export const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL";

export const GET_ALL_ROLE_REQUEST = "GET_ALL_ROLE_REQUEST";
export const GET_ALL_ROLE_SUCCESS = "GET_ALL_ROLE_SUCCESS";
export const GET_ALL_ROLE_FAIL = "GET_ALL_ROLE_FAIL";

export const GET_ALL_TASK_REQUEST = "GET_ALL_TASK_REQUEST";
export const GET_ALL_TASK_SUCCESS = "GET_ALL_TASK_SUCCESS";
export const GET_ALL_TASK_FAIL = "GET_ALL_TASK_FAIL";

export const ASSIGN_TASKS_TO_ROLES_SUCCESS = 'ASSIGN_TASKS_TO_ROLES_SUCCESS';
export const ASSIGN_TASKS_TO_ROLES_FAIL = 'ASSIGN_TASKS_TO_ROLES_FAIL';

export const GET_ROLE_TASKS_REQUEST = 'GET_ROLE_TASKS_REQUEST';
export const GET_ROLE_TASKS_SUCCESS = 'GET_ROLE_TASKS_SUCCESS';
export const GET_ROLE_TASKS_FAIL = 'GET_ROLE_TASKS_FAIL';


export const UPDATE_ROLE_TASK_REQUEST = 'UPDATE_ROLE_TASK_REQUEST';
export const UPDATE_ROLE_TASK_SUCCESS = 'UPDATE_ROLE_TASK_SUCCESS';
export const UPDATE_ROLE_TASK_FAIL = 'UPDATE_ROLE_TASK_FAIL';

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_MESSAGE="CLEAR_MESSAGE";